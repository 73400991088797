import { IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { GridColDef } from '@mui/x-data-grid';
import { convertUTCTimesToLocal, convertUTCTimesToLocalFuncForCheckInOut, getDayOfWeekBasedOnDate } from '../../../../helpers/commonFunctions';
import moment from 'moment';
import { KTCard } from '../../../helpers';
import MuiTable from '../../../../components/table/MuiTable';
import { useMutation } from 'react-query';
import { getAssignmentById, getAssignmentSchedulesByAssignmentId } from '../api';


type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  assignmentId?: number;
  shiftStatusType:string;
  filterData:any;
};

const ViewTotalShift = ({
  show,
  handleClose,
  assignmentId,
  shiftStatusType,
  filterData
}: Props) => {

    const tableRef = useRef();
    const [modalTitle,setModalTitle] = useState('Total')
    const {
        data: assignmentData,
        mutateAsync: getAssignmentData,
        isLoading: gettingAssignmentValues,
    } = useMutation('editassignment', getAssignmentSchedulesByAssignmentId);

    useEffect(() => {
        let statusId = ""
        if(shiftStatusType === "openShift"){  
          statusId = "1"
          setModalTitle('Opened')
        }else if(shiftStatusType === "filledShift"){
          statusId = "2,3,6,7"
          setModalTitle('Filled')
        }else if(shiftStatusType === "cancelledShift"){
          statusId = "4"
          setModalTitle('Cancelled')
        }else if(shiftStatusType === "noShowShift"){
          statusId = "7"
          setModalTitle('No Show')
        }else if(shiftStatusType === "completedShift"){
          statusId = "3"
          setModalTitle('Completed')
        }
        getAssignmentData({ id: assignmentId,shiftStatusType:statusId,shiftId:filterData?.SelectedShift?.id,startTimes:filterData?.StartTimes,endTimes:filterData?.EndTimes});
    }, []);

    const renderStatusCell = (data: any) => {
        let status = data?.row?.StatusName;
        let backgroundColor = '';
        let statusTextColor = '';

        switch (status) {
        case 'Pending':
            backgroundColor = '#007bff1f';
            statusTextColor = '#3495ff';
            break;
        case 'Filled':
            backgroundColor = '#6f42c129';
            statusTextColor = '#855dcf';
            break;
        case 'Rejected':
            backgroundColor = '#ff563029';
            statusTextColor = '#ff5630';
            break;
        case 'Accepted':
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
        case 'Cancelled':
            backgroundColor = '#ff563029';
            statusTextColor = '#ff5630';
            break;
        case 'Completed':
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
        case 'Inprogress':
            backgroundColor = '#d9671629';
            statusTextColor = '#d96716';
            break;
        case 'NoShow':
            backgroundColor = '#d9671629';
            statusTextColor = '#d96716';
            break;

        default:
            backgroundColor = '';
            statusTextColor = '';
            break;
        }
        return (
            <div
              className="d-flex align-items-center"
              style={{
                backgroundColor: backgroundColor,
                height: '30px',
                borderRadius: '5px',
                padding: '10px',
                minWidth: '80px',
                justifyContent: 'center',
              }}
            >
              <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
                {status}
              </span>
            </div>
        );
    }

    const getDayofWeek = (data:any) => {
      const startTime = data?.row?.StartTime;
      const endTime = data?.row?.EndTime;
      const utcAssignmentDate = startTime.split('T')[0];
      const utcStartTime = startTime.split('T')[1];
      const utcEndTime = endTime.split('T')[1];

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const localTime = convertUTCTimesToLocal(
        utcAssignmentDate,
        utcStartTime,
        utcEndTime,
        timezone
      );
      const formattedDate = moment(
        localTime?.assignmentDate,
        'MM-DD-YYYY'
      ).format('MM/DD/YYYY');

      const dayOfDate = getDayOfWeekBasedOnDate(formattedDate)
      return dayOfDate
    }

    const columns: GridColDef[] = [
    {
      field: 'AssignmentDate',
      headerName: 'Assignment Date',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const startTime = data?.row?.StartTime;
        const endTime = data?.row?.EndTime;
        const utcAssignmentDate = startTime.split('T')[0];
        const utcStartTime = startTime.split('T')[1];
        const utcEndTime = endTime.split('T')[1];

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localTime = convertUTCTimesToLocal(
          utcAssignmentDate,
          utcStartTime,
          utcEndTime,
          timezone
        );
        const formattedDate = moment(
          localTime?.assignmentDate,
          'MM-DD-YYYY'
        ).format('MM/DD/YYYY');

        return <>{formattedDate}</>;
      },
    },
    {
      field: 'scheduleTime',
      headerName: 'Schedule Time',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        return <>
      <div>
        {`${convertUTCTimesToLocalFuncForCheckInOut(
          data?.row?.StartTime,
          data?.row?.StartTime,
          data?.row?.EndTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'checkIn'
        )} - ${convertUTCTimesToLocalFuncForCheckInOut(
          data?.row?.StartTime,
          data?.row?.StartTime,
          data?.row?.EndTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'clockOut'
        )}`}
      </div>
        </>;
      },
    },
    {
      field: 'days',
      headerName: 'Days of Week',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const dayOfDate = getDayofWeek(data)
        return <>
        {dayOfDate}
        </>;
      }
    },
    {
      field: 'RatePerHour',
      headerName: 'Rate',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data:any) => {
        const dayOfDate = getDayofWeek(data)
        return (
          <div>
            {
              (dayOfDate === 'Saturday' || dayOfDate === 'Sunday') ?
              <>
                {!data?.row?.WeekOffRate  ? "-" : data?.row?.WeekOffRate?.toFixed(2) + " $/hr"}
              </>:
              <>
                {
                  !data?.row?.RatePerHour  ? "-" : data?.row?.RatePerHour?.toFixed(2) + " $/hr"
                }
              </>
            }
          </div>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Shift Status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: renderStatusCell,
    },
    {
      field: '',
      headerName: 'Assignee',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data:any) => {
        return (
          <div>
            {
             !data?.row?.FirstName && !data?.row?.LastName ? "-" :data?.row?.FirstName + " " + data?.row?.LastName
            }
          </div>
        )
      },
    },
  ];

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-850px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="companyModel">
        <div className="modal-header py-2 px-5">
          <h2 className="mb-0">{modalTitle} Shifts</h2>
          <div
            className="btn btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>
        <div className="p-4" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            <Container fluid>
                <KTCard>
                  <MuiTable
                    columns={columns}
                    isAddEnable={false}
                    data={assignmentData?.Result}
                    tableRef={tableRef}
                    loading={gettingAssignmentValues}
                    isRowClickable={true}
                    //handlePagination={handlePagination}
                    hideFooterPagination={true}
                    //tableOptions={tableOptions}
                  />
                </KTCard>
            </Container>
        </div>
      </Card>
    </Modal>
  );
};
export default ViewTotalShift;
