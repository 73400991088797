// FilterList.tsx
import React from 'react';

interface Filter {
  id: string;
  label: string;
  type:string;
}

interface FilterListProps {
  filters: Filter[]; 
  onRemoveFilter: (filterId: string,type: string) => void;
}

const CommonFilterView: React.FC<FilterListProps> = ({ filters, onRemoveFilter }) => {
  return (
    <div className="filter-list">
      <ul>
        {filters.map((filter, index) => (
          <li key={filter.id || index}>
            {String(filter?.label || filter)}
            <span
              className="filter-close-ico"
              onClick={() => onRemoveFilter(filter.id,filter.type)} 
            >
              x
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CommonFilterView;
