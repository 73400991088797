import { useFormik } from 'formik';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { updateProviderDNRStatusToTerminate } from '../../api';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';

const modalsRoot = document.getElementById('root-modals') || document.body;

const UpdateClinicianStatusToTerminatePopUp = ({
  showPopup,
  handleClose,
  editData,
}: any) => {
  const validationSchema = Yup.object().shape({
    reason: Yup.string().trim().required('Please give reason'),
  });

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'clinician-status-terminate',
    updateProviderDNRStatusToTerminate
  );

  const formik: any = useFormik({
    initialValues: {
      reason: '',
      isChecked: false,
    },
    validationSchema,
    onSubmit: async values => {
      let bodyParams = {
        ProviderId: editData?.Id,
        IsTerminated: true,
        Remarks: values?.reason ?? '',
      };
      let response = await addAPI(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response?.Message);
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.Message);
      }
    },
  });

  const isCheckboxChecked = formik.values.isChecked;

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '14px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>{`Terminate ${editData?.FirstName} ${editData?.LastName} Account`}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <div
              className="p-4"
              style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
            >
              <Col xs={12}>
                <div
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: '#f2f2f2',
                    padding: '10px 15px',
                    display: 'flex',
                    columnGap: '8px',
                  }}
                >
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      color: '#333',
                      marginBottom: '5px',
                    }}
                  >
                    Note:
                  </p>
                  <p style={{ margin: '0', padding: '0' }}>
                    On termination, the user will no longer receive invitations
                    and will be permanently terminated from the application{' '}
                    <span className="fw-bolder" style={{ color: 'red' }}>
                      *
                    </span>
                  </p>
                </div>

                <div className="mt-3">
                  <label
                    className="form-label fw-normal"
                    style={{ fontSize: '16px' }}
                  >
                    Reason for Termination
                    {''}{' '}
                    <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                      *
                    </span>
                  </label>
                  <textarea
                    rows={4}
                    cols={40}
                    className="form-control form-control-solid mb-3"
                    style={{ border: '1px solid #919EAB' }}
                    placeholder="Please provide a reason for terminating this user..."
                    {...formik.getFieldProps('reason')}
                  />
                </div>
                {formik.touched.reason && formik.errors.reason && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.reason}</div>
                  </div>
                )}
              </Col>
              <Row className="mt-8">
                <div className="d-flex align-items-start">
                  <input
                    type="checkbox"
                    id="documentCheckbox"
                    className="me-3 mt-1"
                    {...formik.getFieldProps('isChecked')}
                  />
                  <label
                    htmlFor="documentCheckbox"
                    style={{ cursor: 'pointer', fontSize: '13px' }}
                  >
                    I agree that once I terminate the user, all allocated
                    upcoming shifts will be dropped and the user will not be
                    able to perform any actions during this time.
                  </label>
                </div>
              </Row>
              {/* <hr style={{ width: "100%", borderTop: "1px solid #000" }} /> */}
            </div>
            <Row className="p-4" style={{ borderTop: '1px solid #919eab66' }}>
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  size="lg"
                  variant="primary"
                  disabled={!isCheckboxChecked}
                >
                  {isAdding ? 'Loading...' : 'Confirm Termination'}
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default UpdateClinicianStatusToTerminatePopUp;
