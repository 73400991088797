import { createAsyncThunk } from '@reduxjs/toolkit';
import { APP_URLs, config, userToken } from '../../../constants/appURLs';
import { snackActions } from '../../../helpers/SnackUtilsConfigurator';
import httpsClient from '../../../_metronic/layout/components/api/httpClient';

export const userLogin: any = createAsyncThunk(
  'auth/login',
  async (
    { username, password, callingFrom }: any,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await httpsClient.post(
        APP_URLs.authEndPoints.login,
        { username, password, callingFrom },
        config
      );

      if (data?.IsSuccess) {
        localStorage.setItem('userToken', data?.Result?.Token);
        localStorage.setItem('refreshToken', data?.Result?.RefreshToken);
        localStorage.setItem(
          'RefreshTokenExpiryDate',
          data?.Result?.RefreshTokenExpiryDate
        );
        localStorage.setItem('userInfo', JSON.stringify(data?.Result));
        localStorage.setItem('userRole', data?.Result?.Roles[0].RoleName);
        snackActions.success(data?.Message ?? 'Login Successfully!!');
        window.location.href = '/assignment';
        localStorage.getItem('userInfo');
      } else {
        snackActions.dismissibleError(
          data?.Message ?? 'Something went wrong!!'
        );
      }
    } catch (error: any) {
      snackActions.error(
        error.response.data.Message ?? 'Something went wrong!!'
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userLogout: any = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await httpsClient.put(
        APP_URLs.authEndPoints.logout,
        { token: userToken },
        config
      );
      if (data?.status) {
        localStorage.clear();
        snackActions.success(data.message ?? 'LoggedOut Successfully');
        window.location.reload();
      } else {
        snackActions.dismissibleError(data.message ?? 'Something went wrong!');
      }
    } catch (error: any) {
      snackActions.error(
        error.response.data.message ?? 'Something went wrong!, please try again'
      );
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
