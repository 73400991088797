import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/table/MuiTable';
import { charValidate } from '../../../../components/utility';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import PersonIcon from '@mui/icons-material/Person';
import {
  convertUTCTimesToLocal,
  convertUTCTimesToLocalFuncForCheckInOut,
  formatCharges,
  formatPhoneNumber,
  getDayOfWeekBasedOnDate,
} from '../../../../helpers/commonFunctions';
import useDebounce from '../../../../hooks/useDebounce';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';
import { ReactComponent as Viewinvite } from '../../../assets/svg/viewinvite.svg';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { ReactComponent as Visibility } from '../../../assets/svg/visibility.svg';
import { KTCard } from '../../../helpers';
import {
  DropAssignment,
  getAssignmentById,
  getCompanyDetailsById,
  getViewAssignmentSchedule,
  updateProviderStatus,
} from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import CustomTooltip from '../utility/CustomTooltip';
import { APP_URLs } from '../../../../constants/appURLs';
import { resetStep, setAssignmentFormData } from '../../../../store/slice/form/assignment_form';
import { useDispatch } from 'react-redux';
import { Step4 } from '../../../partials/modals/create-app-stepper/steps/Step4';
import { boolean } from 'yup';

const Viewshift: React.FC = () => {
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [statusData, setStatusData] = useState('');
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showDropConfirmModal, setShowDropConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const tableRef = useRef();
  const navigate = useNavigate();
  const location: any = useLocation();
  const locationData = location?.state;
  localStorage.setItem('locationData', JSON.stringify(locationData));

  const {
    mutateAsync: getViewAssignmentScheduleAPI,
    data: viewAssignmentData,
    isLoading: isLoadingAssignmentScheduleData,
  } = useMutation('assignment-view', getViewAssignmentSchedule);

  const { mutateAsync: dropAssignmentAPI, isLoading: isAssignmentDropped } =
    useMutation('updateProviderStatus', DropAssignment);

  const { mutateAsync: updateProviderData, isLoading: isUpdateProfile } =
    useMutation('updateProviderStatus', updateProviderStatus);
  const dispatch = useDispatch();
  const {
    data: AssignmentEditDataValues,
    mutateAsync: getAssignmentEditDataValues,
    isLoading: gettingAssignmentValues,
  } = useMutation('editassignment', getAssignmentById);

  useEffect(() => {
    getAssignmentEditDataValues({ id: locationData?.Id });
  }, [locationData]);

  const programId = AssignmentEditDataValues?.Result?.ProgramId;
  const skillId = AssignmentEditDataValues?.Result?.CompanySkillIds[0];
  const companyId = AssignmentEditDataValues?.Result?.CompanyId;

  const { data: skillData, mutateAsync: getSkillDataAPI } = useMutation(
    'comapnydetail',
    getCompanyDetailsById
  );

  useEffect(() => {
    if (companyId) {
      getSkillDataAPI({ id: companyId });
    }
  }, [companyId]);

  useEffect(() => {
    if (skillData && skillId) {
      const skillname = skillData?.Result?.CompanySkills?.find(
        (companyskillId: any) => companyskillId?.Id == skillId
      );
      dispatch(
        setAssignmentFormData({ skillIdForProviderSearch: skillname?.SkillId })
      );
    }
  }, [skillId, skillData]);

  const handleInvite = (date: any) => {
    dispatch(
      setAssignmentFormData({
        selectedTabelDates: date.EndTime,
        shiftdetail: AssignmentEditDataValues?.Result?.AssignmentSchedules,
        isforViewInvited: false,
        InvitedUserIds: [],
        programId: programId,
        companyId: companyId,
        isFromViewShift: true,
        viewShiftEditAssignmentData: AssignmentEditDataValues?.Result,
      })
    );
    navigate('/invite-viewshift-assignment', { state: selectedRow });
  };

  const [showStatusModel, setshowStatusModel] = useState({
    id: '',
    status: '',
  });
  const [showUserId, setShowUserId] = useState('');

  useEffect(() => {
    getViewAssignmentScheduleAPI(locationData?.Id);
  }, []);

  const handleViewModalOpen = () => {
    setShowViewModal(true);
    setAnchorEl(null);
    navigate('/view-invite', { state: selectedRow });
  };

  const resultDatas = viewAssignmentData?.Result?.AssignmentSchedules?.[0];
  const assignmentResult = viewAssignmentData?.Result;

  const handleDocumentOpen = (documentId: any) => {
    setLoading(true);
    setError(null);
    try {
      let documentUrl: string | undefined;
      const companyDocument = assignmentResult?.CompanyDocuments.find(
        (document: any) => document?.Id === documentId
      );

      if (companyDocument) {
        documentUrl = `${APP_URLs.viewDocBaseURL}${
          companyDocument.DocumentUrl.startsWith('/') ? '' : '/'
        }${companyDocument.DocumentUrl}`;
      } else {
        const assignmentDocument = assignmentResult?.AssignmentDocuments?.find(
          (document: any) => document?.Id === documentId
        );

        if (assignmentDocument) {
          documentUrl = `${APP_URLs.viewDocBaseURL}${
            assignmentDocument.DocumentUrl.startsWith('/') ? '' : '/'
          }${assignmentDocument.DocumentUrl}`;
        } else {
          throw new Error('Assignment document not found.');
        }
      }
      window.open(documentUrl, '_blank');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
  };

  const updateProviderStatusAPI = async (status: any, userId: any) => {
    let statusValue;
    if (status == 'Approve') {
      statusValue = 2;
    } else if (status == 'Block') {
      statusValue = 4;
    }

    let response = await updateProviderData({
      id: userId,
      status: statusValue,
    });
    if (response?.IsSuccess) {
      setShowDeleteConfirmModal(false);
      snackActions.success(response?.Message);
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? '',
      };
      handleCloseDelete();
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleStatus = (status: string, id: any) => {
    setShowDropConfirmModal(true);
    setshowStatusModel({ status, id });
  };

  const dropAssignment = async (status: any, id: any, reason: any) => {
    let statusValue;
    if (status == 'Drop') {
      statusValue = 4;
    }
    let response = await dropAssignmentAPI({
      id: id?.id,
      status: statusValue,
      reason: reason,
    });
    if (response?.IsSuccess) {
      snackActions.success(response?.Message);
      handleCloseCancelShiftPopUp();
      getViewAssignmentScheduleAPI(locationData?.Id);
    } else {
      snackActions.error(response?.message);
    }
  };

  const handleCloseCancelShiftPopUp = () => {
    setShowDropConfirmModal(false);
    setshowStatusModel({ id: '', status: '' });
  };

  const handleCreateShift = () => {
    dispatch(resetStep())
    navigate('/new-assignment', { state: { assignmenteditdata: locationData, isFromViewShift: true } });
  }

  const renderStatusCell = (data: any) => {
    let status = data?.row?.Status;
    setStatusData(status);
    let backgroundColor = '';
    let statusTextColor = '';

    switch (status) {
      case 'Open':
        backgroundColor = '#007bff1f';
        statusTextColor = '#3495ff';
        break;
      case 'Filled':
        backgroundColor = '#6f42c129';
        statusTextColor = '#855dcf';
        break;
      case 'Rejected':
        backgroundColor = '#ff563029';
        statusTextColor = '#ff5630';
        break;
      case 'Accepted':
        backgroundColor = '#00786729';
        statusTextColor = '#007867';
        break;
      case 'Cancelled':
        backgroundColor = '#ff563029';
        statusTextColor = '#ff5630';
        break;
      case 'Completed':
        backgroundColor = '#00786729';
        statusTextColor = '#007867';
        break;
      case 'Inprogress':
        backgroundColor = '#d9671629';
        statusTextColor = '#d96716';
        break;
      case 'NoShow':
        backgroundColor = '#d9671629';
        statusTextColor = '#d96716';
        break;

      default:
        backgroundColor = '';
        statusTextColor = '';
        break;
    }

    return (
      <div
        className="d-flex align-items-center"
        style={{
          backgroundColor: backgroundColor,
          height: '30px',
          borderRadius: '5px',
          padding: '10px',
          minWidth: '80px',
          justifyContent: 'center',
        }}
      >
        <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
          {status}
        </span>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'AssignmentDate',
      headerName: 'Assignment Date',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const startTime = data?.row?.StartTime;
        const endTime = data?.row?.EndTime;
        const utcAssignmentDate = startTime.split('T')[0];
        const utcStartTime = startTime.split('T')[1];
        const utcEndTime = endTime.split('T')[1];

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localTime = convertUTCTimesToLocal(
          utcAssignmentDate,
          utcStartTime,
          utcEndTime,
          timezone
        );
        const formattedDate = moment(
          localTime?.assignmentDate,
          'MM-DD-YYYY'
        ).format('MM/DD/YYYY');

        return <>{formattedDate}</>;
      },
    },
    {
      field: 'scheduleTime',
      headerName: 'Schedule Time',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        return <>
      <div>
        {`${convertUTCTimesToLocalFuncForCheckInOut(
          data?.row?.StartTime,
          data?.row?.StartTime,
          data?.row?.EndTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'checkIn'
        )} - ${convertUTCTimesToLocalFuncForCheckInOut(
          data?.row?.StartTime,
          data?.row?.StartTime,
          data?.row?.EndTime,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'clockOut'
        )}`}
      </div>
        </>;
      },
    },
    {
      field: 'days',
      headerName: 'Days of Week',
      flex: 3,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const startTime = data?.row?.StartTime;
        const endTime = data?.row?.EndTime;
        const utcAssignmentDate = startTime.split('T')[0];
        const utcStartTime = startTime.split('T')[1];
        const utcEndTime = endTime.split('T')[1];

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const localTime = convertUTCTimesToLocal(
          utcAssignmentDate,
          utcStartTime,
          utcEndTime,
          timezone
        );
        const formattedDate = moment(
          localTime?.assignmentDate,
          'MM-DD-YYYY'
        ).format('MM/DD/YYYY');

        const dayOfDate = getDayOfWeekBasedOnDate(formattedDate)
        return <>
        {dayOfDate}
        </>;
      }
    },
    {
      field: 'status',
      headerName: 'Shift Status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: renderStatusCell,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="viewInvitePopover">
                <Col className="pb-2" onClick={handleViewModalOpen}>
                  <Viewinvite />
                  <label className="ps-3">View Invite</label>
                </Col>
                {(selectedRow?.Status === 'Open' || selectedRow?.Status === 'Filled') && assignmentResult?.Status != "Cancelled"? (
                  <Col className="pb-2" onClick={() => handleInvite(selectedRow)}>
                  <PersonIcon />
                  <label className="ps-3">Invite</label>
                </Col>
                ): null}
                {(selectedRow?.Status === 'Open' || selectedRow?.Status === 'Filled') && assignmentResult?.Status != "Cancelled" ? (
                  <Col
                    className="pb-2"
                    onClick={() => {
                      handleStatus('Drop', { id: selectedRow?.Id });
                      handleClose();
                    }}
                  >
                    <Delete />
                    <label className="ps-3">Cancel Shift</label>
                  </Col>
                ) : null}
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];
  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
    };
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleBackClick = () => {
    navigate('/assignment');
  };

  return (
    <div>
      <div className="mb-5 backArrow" onClick={handleBackClick}>
        <ArrowBackIcon />
      </div>

      <div className="position-relative">
        <div className="row">
          <div className="col-xl-4">
            <div className="shiftDetail-sticky card p-4 customeboxShadow">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-between my-4 pb-3 inviteDetailTitle">
                    <h3 className="mb-0">{assignmentResult?.AssignmentCode}</h3>
                    <span className="fw-normal titleFont">
                      {assignmentResult?.CompanyName}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-4 pb-3 inviteDetailTitle">
                <div className="row">
                  <div className="col-6">
                    <h5>Patient Info:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p className="mb-2">
                      {assignmentResult?.PatientDetails?.FirstName &&
                      assignmentResult?.PatientDetails?.LastName
                        ? `${viewAssignmentData.Result.PatientDetails.FirstName} ${viewAssignmentData.Result.PatientDetails.LastName}`
                        : 'Loading...'}
                    </p>
                    <p className="mb-2">
                      {`+${formatPhoneNumber(
                        assignmentResult?.PatientDetails?.CountryCode +
                          assignmentResult?.PatientDetails?.ContactNumber
                      )}`}
                    </p>
                  </div>
                  <div className="col-6">
                    <h5>Secondary Info:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p className="mb-2">
                      {assignmentResult?.PatientDetails
                        ?.ContactPersonFirstName ||
                      assignmentResult?.PatientDetails?.ContactPersonLastName
                        ? `${
                            assignmentResult?.PatientDetails
                              ?.ContactPersonFirstName || ''
                          } ${
                            assignmentResult?.PatientDetails
                              ?.ContactPersonLastName || ''
                          }`
                        : '-'}
                    </p>
                    <p className="mb-2">
                      {assignmentResult?.PatientDetails
                        ?.ContactPersonContactNumber
                        ? `+${formatPhoneNumber(
                            viewAssignmentData.Result.PatientDetails
                              .CountryCode +
                              viewAssignmentData.Result.PatientDetails
                                .ContactPersonContactNumber
                          )}`
                        : ''}
                    </p>
                  </div>
                  {/* <div className="col-6">
                    <h5>Scheduled Time:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p className="mb-2">
                      {`${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'checkIn'
                      )} - ${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'clockOut'
                      )}`}
                    </p>
                  </div> */}
                  <div className="col-6">
                    <h5>Weekdays Rate:</h5>
                    <h5>Weekend Rate:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p className="mb-2">
                      {formatCharges(assignmentResult?.RatePerHour) ?? '-'}
                    </p>
                    <p className="mb-2">
                      {formatCharges(assignmentResult?.WeekOffRate) ?? '-'}
                    </p>
                  </div>
                  <div className="col-6">
                    <h5>Location:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p className="mb-2">
                      {assignmentResult?.AddressLine1 &&
                      assignmentResult?.AddressLine1
                        ? assignmentResult?.AddressLine1 +
                          ', ' +
                          assignmentResult?.City +
                          ', ' +
                          assignmentResult?.ZipCode +
                          ', ' +
                          assignmentResult?.StateAcronym
                        : 'Loading..'}
                    </p>
                  </div>
                  <div className="col-6">
                    <h5>Instructions:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p>
                      <CustomTooltip
                        title={
                          assignmentResult?.Instruction ||
                          'No instructions available'
                        }
                      >
                        <p className="mb-2">
                          {assignmentResult?.Instruction
                            ? charValidate(assignmentResult?.Instruction, 100)
                            : '-'}
                        </p>
                      </CustomTooltip>
                    </p>
                  </div>
                  <div className="col-6">
                    <h5>Other Info:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    {assignmentResult?.PatientDetails
                      ?.PatientOtherInformations ? (
                      <ul>
                        {viewAssignmentData.Result.PatientDetails.PatientOtherInformations.map(
                          (otherinfo: any, index: any) => (
                            <li key={index} style={{ fontWeight: 'light' }}>
                              {otherinfo?.OtherInformationDescription}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <p className="mb-2">N/A</p>
                    )}
                  </div>
                  <div className="col-6">
                    <h5>Notes:</h5>
                  </div>
                  <div className="col-6 fw-normal">
                    <p>
                      <CustomTooltip
                        title={
                          assignmentResult?.PatientDetails?.Note ||
                          'No notes available'
                        }
                      >
                        <p className="mb-2">
                          {assignmentResult?.PatientDetails?.Note
                            ? charValidate(
                                assignmentResult?.PatientDetails?.Note,
                                100
                              )
                            : '-'}
                        </p>
                      </CustomTooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <h6 className="mb-3">Total Shifts:</h6>
                  <h6 className="mb-3">Opened</h6>
                  <h6 className="mb-3">Filled</h6>
                  <h6 className="mb-3">Status</h6>
                </div>
                <div className="col-6">
                  <h6
                    className="mb-3"
                    style={{ fontWeight: '400', color: '#212B36' }}
                  >
                    {assignmentResult?.TotalShiftCount}
                  </h6>
                  <h6
                    className="mb-3"
                    style={{ fontWeight: '400', color: '#212B36' }}
                  >
                    {assignmentResult?.OpenShiftCount}
                  </h6>
                  <h6
                    className="mb-3"
                    style={{ fontWeight: '400', color: '#212B36' }}
                  >
                    {assignmentResult?.FilledShiftCount}
                  </h6>
                  <h6
                    className="mb-3"
                    style={{ fontWeight: '400', color: '#212B36' }}
                  >
                    {assignmentResult?.Status}
                  </h6>
                </div>
              </div>
              <Row>
                <Col xs={12}>
                  <div
                    className="mt-2 pt-2"
                    style={{ borderTop: '1px solid rgb(145, 158, 171)' }}
                  >
                    {(assignmentResult?.CompanyDocuments?.length > 0 ||
                      assignmentResult?.AssignmentDocuments?.length > 0) && (
                      <label className="form-label fw-bolder mb-3">
                        Documents
                      </label>
                    )}
                    {assignmentResult?.CompanyDocuments.map(
                      (document: any, index: number) => (
                        <Card
                          key={index}
                          className="mb-3"
                          style={{
                            boxShadow: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#f1f3f4',
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between px-4 py-2">
                            <Typography variant="subtitle1">
                              {document.FileName}
                            </Typography>
                            <IconButton
                              edge="end"
                              onClick={() => handleDocumentOpen(document?.Id)}
                            >
                              <Visibility />
                            </IconButton>
                          </div>
                        </Card>
                      )
                    )}

                    {assignmentResult?.AssignmentDocuments.map(
                      (document: any, index: number) => (
                        <Card
                          key={index}
                          className="mb-5"
                          style={{
                            boxShadow: 'none',
                            borderRadius: '5px',
                            backgroundColor: '#f1f3f4',
                          }}
                        >
                          <div className="d-flex align-items-center justify-content-between px-4 py-2">
                            <Typography variant="subtitle1">
                              {document.FileName}
                            </Typography>
                            <IconButton
                              edge="end"
                              onClick={() => handleDocumentOpen(document?.Id)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </div>
                        </Card>
                      )
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="col-xl-8">
            <Card className="p-6 customeboxShadow">
            {assignmentResult?.Status != "Cancelled" && (
              <div className='d-flex align-items-end justify-content-end'>
          <Button
              className="d-flex align-items-center btn btn-primary"
              onClick={handleCreateShift}
              >
                Create Shift
              </Button>
              </div>)}
              <>
                <KTCard>
                  <MuiTable
                    columns={columns}
                    isAddEnable={false}
                    data={viewAssignmentData?.Result?.AssignmentSchedules}
                    tableRef={tableRef}
                    loading={isLoadingAssignmentScheduleData}
                    isRowClickable={true}
                    handlePagination={handlePagination}
                    hideFooterPagination={true}
                    tableOptions={tableOptions}
                  />
                </KTCard>

                {showDeleteConfirmModal && (
                  <ConfirmEventPopup
                    showPopup={showDeleteConfirmModal}
                    handleClose={handleCloseDelete}
                    handleConfirmed={() =>
                      updateProviderStatusAPI(showStatusModel, showUserId)
                    }
                    loading={isUpdateProfile}
                    title={'Update Status'}
                    message={`Are you sure, do you want to ${showStatusModel} this Profile?`}
                  />
                )}
              </>
            </Card>
          </div>
        </div>
      </div>
      {showDropConfirmModal && (
        <ConfirmEventPopup
          showPopup={showDropConfirmModal}
          handleClose={handleCloseCancelShiftPopUp}
          handleConfirmed={reason =>
            dropAssignment(showStatusModel.status, showStatusModel.id, reason)
          }
          loading={isAssignmentDropped}
          title={'Cancel Shift'}
          placeHolder={'Enter reason for cancel'}
          message={`Are you sure, do you want to cancel this Shift?`}
          rejectReasonRequired={showStatusModel.status}
        />
      )}
    </div>
  );
};

export default Viewshift;


