import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  convertDateToMMDDYYYY,
  convertUTCTimesToLocal,
  convertUTCTimesToLocalFuncForCheckInOut,
  formatTimeForCheckInOut,
  convertToDateTime
} from '../../../../../helpers/commonFunctions';
import {
  nextStep,
  prevStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getShift } from '../../api';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import './stepper-style.css';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import { AssignmentscheduleStatusEnum } from '../../../../helpers/enum'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel,
  Box,
  Select,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';


export const Step_1 = () => {
  const currentDate = moment().format('YYYY-MM-DD');
  const todayDate = new Date();
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState();
  const [assignmentShiftDetails, setAssignmentShiftDetails] = useState([]);
  const [open, setOpen] = useState(false);
 
  const formDatas = useSelector(state => state.assignment.formData);
  const Step_1_validation_schema = Yup.object().shape({
    dateforshift: Yup.array()
      .min(1, 'Date is required')
      .required('Date is required'),
    noOfPersonRequired: Yup.string(),
  });

  const { data: shiftData } = useQuery('shift', getShift);

  const StyledDatePicker = styled(DatePicker)`
    width: 100% !important;
    height: 46.8px;
    border: 1px solid rgb(145, 158, 171);
    color: black;
    padding: 15px;
    border-radius: 8px;
    background-color: var(--bs-gray-100);
  `;

  const formik = useFormik({
    initialValues: {
      dateforshift: formDatas?.assignmentSchedules?.dateforshift ?? [],
      noOfPersonRequired: 1,
    },
    validationSchema: Step_1_validation_schema,
    onSubmit: async values => {
      const updatedValues = {
        shiftData:shiftData,
        assignmentSchedules: assignmentShiftDetails,
      };
      for (const item of assignmentShiftDetails) {
        for (const slot of item?.timeSlots || []) {
          if (!slot?.isCustomShiftTime) {
            if(!slot?.shiftId){
              snackActions.error(`Please select shift time of ${convertDateToMMDDYYYY(item?.assignmentDate)}`);
              return;
            }
          }else{
            if(!slot?.startTime || !slot.endTime){
              snackActions.error(`Please select shift time of ${convertDateToMMDDYYYY(item?.assignmentDate)}`);
              return;
            }
          }
        }
      }
        dispatch(setAssignmentFormData(updatedValues));
        dispatch(nextStep());
    },
  });

  useEffect(() => {
    if (formDatas) {
      if(formDatas?.assignmentSchedules.length){
        const selectedDates = formDatas?.assignmentSchedules.map(x=>moment(x.assignmentDate).toDate())
        formik.setFieldValue('dateforshift', selectedDates);
        setSelectedDates(selectedDates)
        const sortingData = (formDatas?.assignmentSchedules || []).slice().sort((a, b) =>
          new Date(a.assignmentDate).getTime() - new Date(b.assignmentDate).getTime()
        );
        setAssignmentShiftDetails(sortingData)
      }
     }
  }, [formDatas]);

  const onChangeDateShift = (dates) => {
    const filterNewDate = dates?.filter((date) => !selectedDates?.includes(date));
    if (filterNewDate?.length > 0) {
      setAssignmentShiftDetails((prevDetails) => [
        ...prevDetails,
        ...filterNewDate.map((date) => ({
          assignmentDate: date,
          timeSlots: [
            {
              startTime: '',
              endTime: '',
              type: 'default',
              isCustomShiftTime: false, 
              invitedUserIds: [],
              shiftId:0
            },
          ],
        })),
      ].sort((a, b) => new Date(a.assignmentDate).getTime() - new Date(b.assignmentDate).getTime())
    );
    } else {
      const updatedassignmentShiftDetails = assignmentShiftDetails?.filter((detail) =>
        dates?.includes(detail?.assignmentDate)
      );
      setAssignmentShiftDetails(updatedassignmentShiftDetails);
    }
  
    setSelectedDates(dates);
    formik.setFieldValue('dateforshift', dates);
  };

  const handleShiftOptionChange = (type, index,slotIndex) => {
    const updatedAssignmentShiftDetails = [...assignmentShiftDetails];
    updatedAssignmentShiftDetails[slotIndex] = {
      ...updatedAssignmentShiftDetails[slotIndex], 
      timeSlots: [...updatedAssignmentShiftDetails[slotIndex].timeSlots]
    };
    if(type == "default"){
      updatedAssignmentShiftDetails[slotIndex].timeSlots[index] = {
        ...updatedAssignmentShiftDetails[slotIndex].timeSlots[index],
        startTime:'',
        endTime:'',
        type: type,
        isCustomShiftTime: false
      };
    }else{
      updatedAssignmentShiftDetails[slotIndex].timeSlots[index] = {
        ...updatedAssignmentShiftDetails[slotIndex].timeSlots[index],
        shiftId : 0,
        type: type,
        isCustomShiftTime: true
      };
    }
    setAssignmentShiftDetails(updatedAssignmentShiftDetails)
  };

  const handleAddShift = (slotIndex) => {
   const timeSlot = {
      startTime: '',
      endTime: '',
      type: 'default',
      isCustomShiftTime: false, 
      invitedUserIds: [],
      shiftId:0
    }
    const updatedAssignmentShiftDetails = [...assignmentShiftDetails];
    updatedAssignmentShiftDetails[slotIndex] = {
      ...updatedAssignmentShiftDetails[slotIndex],
      timeSlots: [...updatedAssignmentShiftDetails[slotIndex].timeSlots, timeSlot],
    };
    
    setAssignmentShiftDetails(updatedAssignmentShiftDetails)
  };
  
  const handleRemoveShift = (shiftIndex, slotIndex) => {
    const updatedAssignmentShiftDetails = [...assignmentShiftDetails];
    const updatedTimeSlots = [...updatedAssignmentShiftDetails[slotIndex].timeSlots];
    updatedTimeSlots.splice(shiftIndex, 1);
    updatedAssignmentShiftDetails[slotIndex] = {
      ...updatedAssignmentShiftDetails[slotIndex],
      timeSlots: updatedTimeSlots,
    };
    setAssignmentShiftDetails(updatedAssignmentShiftDetails);
  };

  const handleShiftTimeChange = (e, index,slotIndex) => {
    const selectedShiftId = e.target.value;
    const updatedAssignmentShiftDetails = [...assignmentShiftDetails];
    updatedAssignmentShiftDetails[slotIndex] = {
      ...updatedAssignmentShiftDetails[slotIndex], 
      timeSlots: [...updatedAssignmentShiftDetails[slotIndex].timeSlots]
    };
    updatedAssignmentShiftDetails[slotIndex].timeSlots[index] = {
      ...updatedAssignmentShiftDetails[slotIndex].timeSlots[index],
      shiftId: selectedShiftId
    };
    setAssignmentShiftDetails(updatedAssignmentShiftDetails)
  };

  const normalizeDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);  // Set time to midnight (00:00:00)
    return normalizedDate;
  };

  const removeDate = dateToRemove => {
    const isPastDate = (normalizeDate(dateToRemove).getTime() < normalizeDate(todayDate).getTime()) || formattedAcceptedShiftDate.some((shiftDate) => normalizeDate(dateToRemove).getTime() === normalizeDate(shiftDate).getTime())
    if(!isPastDate){
      const updatedDates = selectedDates.filter(date => date !== dateToRemove);
    setSelectedDates(updatedDates);
    formik.setFieldValue('dateforshift', updatedDates);
    }
  };

  const handleTimeChange = (field, newTime, assignmentDate, index,slotIndex) => {
    const updatedAssignmentShiftDetails = [...assignmentShiftDetails];
    updatedAssignmentShiftDetails[slotIndex] = {
      ...updatedAssignmentShiftDetails[slotIndex], 
      timeSlots: [...updatedAssignmentShiftDetails[slotIndex].timeSlots]
    };
    
    if (field === 'startTime') {
      updatedAssignmentShiftDetails[slotIndex].timeSlots[index] = {
        ...updatedAssignmentShiftDetails[slotIndex].timeSlots[index],
        startTime: newTime
      };
    } else if (field === 'endTime') {
      updatedAssignmentShiftDetails[slotIndex].timeSlots[index] = {
        ...updatedAssignmentShiftDetails[slotIndex].timeSlots[index],
        endTime: newTime
      };
    }
   
    setAssignmentShiftDetails(updatedAssignmentShiftDetails)
  };

  const isPastDate = (assignmentDate) => {
    const currentDate = moment().format('YYYY-MM-DD'); // Ensure format matches assignmentDate
    return moment(assignmentDate, 'YYYY-MM-DD').isBefore(moment(currentDate, 'YYYY-MM-DD'));
  };

  const convertedAcceptedShift= formDatas?.clinicianshiftdetails?.map((data) =>
    {
      const utcAssignmentDate = data?.StartTime?.split('T')[0];
        const utcStartTime = data?.StartTime?.split('T')[1];
        const utcEndTime = data?.EndTime?.split('T')[1]
        const shiftStatus = data?.StatusId

        let date = convertUTCTimesToLocal(
          utcAssignmentDate,
          utcStartTime,
          utcEndTime
        )
        return {
            ...date,      
            shiftStatus 
        };
    }
    )

    const shiftStausIds = [
      AssignmentscheduleStatusEnum?.Accepted,
      AssignmentscheduleStatusEnum?.Completed,
      AssignmentscheduleStatusEnum?.Dropped,
      AssignmentscheduleStatusEnum?.Rejected,
      AssignmentscheduleStatusEnum?.Inprogress,
      AssignmentscheduleStatusEnum?.NoShow
    ]
  
    const formattedAcceptedShiftDate = convertedAcceptedShift?.filter((id) => shiftStausIds?.includes(id?.shiftStatus))?.map((id) => moment(id?.assignmentDate));

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className='d-flex w-100'>
          <div className='me-4'>
            <label><strong className='me-1'>Assignment Name:</strong> {formDatas?.assignmentName ? formDatas?.assignmentName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Facility:</strong> {formDatas?.companyName ? formDatas?.companyName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Skill:</strong> {formDatas?.skillName ? formDatas?.skillName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Program:</strong> {formDatas?.programName ? formDatas?.programName : '-'}</label>
          </div>
        </div>
        <div className="my-5">
          <h3>Create Shift</h3>
        </div>
        <Row className="gy-4 mb-4">
        <Col md={6} xs={12}>
            <div className="d-flex justify-content-between">
              <div className="rateTitle">
                <label
                  className="form-label fw-normal"
                  style={{ fontSize: '16px' }}
                >
                  Rate ($/hr)
                  <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                    {' '}
                    *
                  </span>
                </label>
              </div>
              <div className="w-100 pe-3">
                <label className="form-label fw-bold">
                  Weekday <span className="fw-bolder mt-5 text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 WeeklyRate"
                  placeholder="Weekday"
                  aria-label="weekday"
                  style={{
                    backgroundColor: '#e0e0e0',
                  }}
                  value={formDatas?.ratePerHour?.toFixed(2)}
                  disabled
                />
                {formik?.touched?.weekday && formik?.errors?.weekday && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.weekday}</div>
                  </div>
                )}
              </div>
              <div className="w-100">
                <label className="form-label fw-bold">
                  Weekend <span className="fw-bolder mt-5 text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 WeeklyRate"
                  placeholder="Weekend"
                  aria-label="weekend"
                  style={{
                    backgroundColor: '#e0e0e0',
                  }}
                  disabled
                  value={formDatas?.weekendrate?.toFixed(2)}
                />
                {formik?.touched?.weekend && formik?.errors?.weekend && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.weekend}</div>
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">
              No of Person Required
              <span className="fw-bolder mt-5 text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              aria-label="rateperhour"
              style={{
                border: '1px solid #919EAB',
                color: 'black',
                padding: '15px',
                backgroundColor: '#e0e0e0',
              }}
              value="1"
              disabled
            />
          </Col>

          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">
              Start Date
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>

            <div className="input-group custome">
              <StyledDatePicker
                showIcon
                selectedDates={selectedDates}
                selectsMultiple
                {...formik.getFieldProps('dateforshift')}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                placeholderText="MM/DD/YYYY"
                minDate={new Date()}
                readOnly={true}
                onChange={onChangeDateShift}
                autoComplete="off"
                onInputClick={() => setOpen(true)} 
                onClickOutside={() => setOpen(false)} 
                open={open}
                //excludeDates={formattedAcceptedShiftDate}
              />{' '}
              <br />
              {formik.touched.dateforshift && formik.errors.dateforshift && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.dateforshift}
                  </div>
                </div>
              )}
            </div>
            {/* <div>
              {selectedDates && selectedDates?.length > 0 && (
                <div className="selected-dates-container mt-3 d-flex flex-wrap selectedDateView">
                  {selectedDates?.map((date, index) => (
                    <div key={index} className={`chip d-flex align-items-center ${(normalizeDate(date).getTime() < normalizeDate(todayDate).getTime()) || formattedAcceptedShiftDate.some((shiftDate) => normalizeDate(date).getTime() === normalizeDate(shiftDate).getTime())
                      ? "datePickerDisable"
                      : "" ? "datePickerDisable" : ""}`}>
                      {convertDateToMMDDYYYY(date)}
                      <HighlightOffIcon
                        className="remove-icon"
                        onClick={() => removeDate(date)}
                        style={{cursor: (normalizeDate(date).getTime() < normalizeDate(todayDate).getTime()) || formattedAcceptedShiftDate.some((shiftDate) => normalizeDate(date).getTime() === normalizeDate(shiftDate).getTime()) ? "not-allowed" : "pointer"}}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div> */}
          </Col>
        </Row>
        {
          assignmentShiftDetails?.length > 0 && (
            <label className="form-label fw-normal inputTitle pe-5">
              Manage Shift Time
              <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
          )
        }
        
        {assignmentShiftDetails?.map((shiftdata, index) => (
          <Accordion key={index} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`${shiftdata?.assignmentDate}-content`}
              id={`${shiftdata?.assignmentDate}-header`}
            >
              <Typography className="text-capitalize">
                {moment(shiftdata?.assignmentDate).format('MM/DD/YYYY')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Col md={12} xs={12}>
                <div>
                  <Row>
                    <Col xs="12">
                      <div key={index}>
                        {shiftdata?.timeSlots?.map((item, shiftIndex) => (
                          <div key={shiftIndex} className="mt-2">
                            <div className="row">
                              <div className="col-1">
                                <label className="d-flex align-items-center h-100">
                                  <input
                                    type="radio"
                                    name={`shift-option-${index}-${shiftIndex}`} 
                                    checked={item?.type == 'default'} 
                                    onChange={() => handleShiftOptionChange('default', shiftIndex,index)}
                                    disabled={item?.Id}
                                  /> 
                                  <p className="mb-0 ms-2">Default</p>
                                </label>
                              </div>
                              <div className="col-1">
                                <label className="d-flex align-items-center h-100">
                                  <input
                                    type="radio"
                                    name={`shift-option-${index}-${shiftIndex}`} 
                                    checked={item?.type == 'custom'} 
                                    onChange={() => handleShiftOptionChange('custom', shiftIndex,index)}
                                    disabled={item?.Id}
                                  /> 
                                  <p className="mb-0 ms-2">Custom</p>
                                </label>
                              </div>
                              {item?.type == 'default' && (
                                <div className='col-3'>
                                  <Select
                                    style={{
                                      border: '1px solid #919EAB',
                                      backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                                      height: '40px', 
                                      width:'100%'
                                    }}
                                    placeholder='Select shift'
                                    name={`select-shift-${index}-${shiftIndex}`} 
                                    value={item?.shiftId || 0}
                                    onChange={(e) => handleShiftTimeChange(e, shiftIndex,index)}
                                    disabled={item?.Id}
                                  >
                                    <MenuItem value='0'>Select Shift</MenuItem>
                                    {shiftData?.Result?.map((shift) => {
                                      const { startTime, endTime } = convertUTCTimesToLocal(currentDate, shift?.StartTime, shift?.EndTime);
                                      return (
                                        <MenuItem value={shift?.Id} key={shift?.Id}>
                                          {startTime?.slice(0, 5)} - {endTime?.slice(0, 5)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </div>
                              )}
                            {
                              item?.type == 'custom' && (
                                <>
                                  <div className='col-3 ctmTime'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                          ampm={false}
                                          value={dayjs(item.startTime)}
                                          timeSteps={{ minutes: 1 }}
                                          sx={{
                                            border: '1px solid',
                                            borderRadius: '5px',
                                            backgroundColor: item?.Id ? '#e0e0e0' : '',
                                          }}
                                          onChange={(newValue) =>
                                            handleTimeChange('startTime', newValue, shiftdata.assignmentDate, shiftIndex,index)
                                          }
                                          disabled={item?.Id}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                  <div className='col-3 ctmTime'>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DemoContainer components={['TimePicker']}>
                                        <TimePicker
                                          ampm={false}
                                          value={dayjs(item.endTime)}
                                          timeSteps={{ minutes: 1 }}
                                          sx={{
                                            border: '1px solid',
                                            borderRadius: '5px',
                                            backgroundColor: item?.Id ? '#e0e0e0' : '',
                                          }}
                                          onChange={(newValue) =>
                                            handleTimeChange('endTime', newValue, shiftdata.assignmentDate, shiftIndex,index)
                                          }
                                          disabled={item?.Id}
                                        />
                                      </DemoContainer>
                                    </LocalizationProvider>
                                  </div>
                                  </>
                              )
                            }
                              {(shiftIndex == 0 && !isPastDate(shiftdata?.assignmentDate)) && (
                                <div className="col-3">
                                  <div className="d-flex align-items-center h-100">
                                    <AddCircleIcon
                                      fontSize="large" 
                                      onClick={() => handleAddShift(index)}
                                    />
                                  </div>
                                </div>
                              )}
                             {(shiftIndex > 0 && !item.Id) && (
                                <div className="col-3">
                                  <div className="d-flex align-items-center h-100">
                                    <RemoveCircleIcon
                                      sx={{ fontSize: '2.2rem', color: 'red' }}
                                      onClick={() => handleRemoveShift(shiftIndex, index)}
                                      disabled={item?.Id}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </AccordionDetails>
          </Accordion>
        ))}

        <div className="d-flex justify-content-between mt-10 mb-2">
          <Button
            size="lg"
            className="px-10"
            onClick={() => dispatch(prevStep())}
            variant="secondary"
          >
            Back
          </Button>

          <Button type="submit" size="lg" variant="primary">
            Save & Next
          </Button>
        </div>
      </form>
    </>
  );
};
