import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';

import moment from 'moment';
import { createShift, editShift } from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import './shift.css';
import {
  convertTimesToUTC,
  convertToDateTime,
  convertUTCTimesToLocal,
  formatTime,
  formatTimeForCheckInOut,
} from '../../helpers/commonFunctions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    Id: any;
    StartTime: string;
    EndTime: string;
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const CreateShiftPage = ({
  show,
  handleClose,
  editData,
  isForEditProfile = false,
}: Props) => {
  const [startTime, setStartTime] = useState<any>('');
  const [endTime, setEndTime] = useState<any>('');

  const validationSchema = Yup.object().shape({
    startTime: Yup.string()
      .matches(
        /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/,
        'Start time must be in HH:mm format'
      )
      .required('Start time is required'),
    endTime: Yup.string()
      .required('End time is required')
      .matches(
        /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/,
        'End time must be in HH:mm format'
      )
      .test(
        'shift-duration',
        "Shift duration can't be more than 13 hours",
        function (value) {
          const { startTime } = this.parent;
          if (!startTime || !value) return true;

          const start = new Date(`January 1, 1970 ${startTime}`);
          const end = new Date(`January 1, 1970 ${value}`);

          let timeDiffInHours;

          if (end < start) {
            end.setHours(end.getHours() + 24);
          }

          timeDiffInHours =
            (end.getTime() - start.getTime()) / (1000 * 60 * 60);

          if (timeDiffInHours > 13) return false;

          return true;
        }
      )
      .test(
        'min-duration',
        'End time must be at least 30 minutes after start time',
        function (value) {
          const { startTime } = this.parent;
          if (!startTime || !value) return true;
    
          const start = new Date(`January 1, 1970 ${startTime}`);
          const end = new Date(`January 1, 1970 ${value}`);
    
          if (end < start) {
            end.setHours(end.getHours() + 24);
          }
    
          const timeDiffInMinutes = (end.getTime() - start.getTime()) / (1000 * 60);
    
          return timeDiffInMinutes >= 30;
        }
      )
  });

  const formik: any = useFormik({
    initialValues: {
      startTime: '',
      endTime: '',
    },
    validationSchema,
    onSubmit: async values => {
      const currentDate = moment().format('YYYY-MM-DD');

      const convertedTimes = convertTimesToUTC(
        currentDate,
        values.startTime,
        values.endTime
      );

      const formattedStartTime = formatTime(new Date(convertedTimes.startTime));
      const formattedEndTime = formatTime(new Date(convertedTimes.endTime));

      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
      };

      if (editData) {
        let response = await edtiShift(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          formik.resetForm();
          if (isForEditProfile) {
            localStorage.setItem('userInfo', JSON.stringify(response?.data));
          }
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      } else {
        let response = await addShift(bodyParams);
        if (response?.IsSuccess) {
          snackActions.success(response.Message);
          formik.resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      }
    },
  });

  useEffect(() => {
    if (editData) {
      const currentDate = moment().format('YYYY-MM-DD');
      const convertedTimes = convertUTCTimesToLocal(
        currentDate,
        editData.StartTime,
        editData.EndTime
      );
      const formattedStartTime = convertToDateTime(currentDate, convertedTimes?.startTime)
      const formattedEndTime = convertToDateTime(currentDate, convertedTimes?.endTime)
      setStartTime(formattedStartTime)
      setEndTime(formattedEndTime)
      const Starttime = convertedTimes?.startTime.slice(0, 5);
      const Endtime = convertedTimes?.endTime.slice(0, 5);
      formik.setFieldValue('startTime', Starttime);
      formik.setFieldValue('endTime', Endtime);
    }
  }, [editData]);

  const { mutateAsync: addShift, isLoading: isAdding } = useMutation(
    'add-nursinghome',
    createShift
  );
  const { mutateAsync: edtiShift, isLoading: isEditing } = useMutation(
    'edit-nursinghome',
    editShift
  );

  const handleTimeChange = (fieldName: String, value: any) => {
    if (fieldName === 'startTime') {
      setStartTime(editData?.Id ? value : formatTimeForCheckInOut(value?.$d));
    } else if (fieldName === 'endTime') {
      setEndTime(editData?.Id ? value : formatTimeForCheckInOut(value?.$d));
    }
    formik.setFieldValue(fieldName, formatTimeForCheckInOut(value?.$d));
    formik.validateField(fieldName);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="nursingModel">
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit Shift Time' : 'Create Shift Time'}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="gy-sm-3 gy-2">
                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    Start Time{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  {/* <input
                    type="time"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 shiftInput"
                    value={formik.values.startTime || ''}
                    aria-label="start time"
                    onChange={(e: any) =>
                      handleTimeChange('startTime', e.target.value)
                    }
                    step="1800"
                  /> */}
                   <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker 
        ampm={false}
        value={startTime}
        timeSteps={{ minutes: 1 }}
        sx={{
          border: "1px solid",
          borderRadius: "5px"
        }}
        onChange={(newValue: any) =>
          handleTimeChange('startTime', newValue)
        }
        />
      </DemoContainer>
    </LocalizationProvider>
                  {formik.touched.startTime && formik.errors.startTime && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startTime}
                      </div>
                    </div>
                  )}
                </Col>

                <Col sm={6} xs={12}>
                  <label className="form-label fw-bold">
                    End Time{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  {/* <input
                    type="time"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 shiftInput"
                    value={formik.values.endTime || ''}
                    aria-label="end time"
                    onChange={(e: any) =>
                      handleTimeChange('endTime', e.target.value)
                    }
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker 
        ampm={false}
        value={endTime}
        timeSteps={{ minutes: 1 }}
        sx={{
          border: "1px solid",
          borderRadius: "5px"
        }}
        onChange={(newValue: any) =>
          handleTimeChange('endTime', newValue)
        }
        />
      </DemoContainer>
    </LocalizationProvider>
                  {formik.touched.endTime && formik.errors.endTime && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endTime}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-6 py-4">
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default CreateShiftPage;
