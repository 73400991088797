import { useEffect, useState } from 'react';
import { Popover, styled, Typography } from '@mui/material';
import { getCities, getCompanies, getProgram,getShift, getSkills, getSkillsData, getStates } from '../api';
import { useMutation, useQuery } from 'react-query';
import RestoreIcon from '@mui/icons-material/Restore';
import CustomTooltip from '../utility/CustomTooltip';
import { KTIcon } from '../../../helpers';
import DatePicker from 'react-datepicker';
import { Button } from 'react-bootstrap';
import { isAdmin, isSuperAdmin } from '../../../../hooks/useUserInfo';
import { RoleEnum } from '../../../helpers/enum';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { convertDateToYYYYMMDDForAssignment, convertTimesToUTCForAssignment, convertUTCTimesToLocal, getEndOfDayUTC, getStartOfDayUTC } from '../../../../helpers/commonFunctions';
import moment from 'moment';

interface IdName  {
  id:any,
  name:string,
}

const FilterCommonPopUp = ({
  showPopup,
  handleClose,
  page,
  values,
}: {
  showPopup: boolean;
  handleClose: (obj: any, isForClose: boolean) => void;
  page: string;
  values: any;
}) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedState, setSelectedState] =   useState<{ id?: string, name?: string }>({}); 
  const [selectedCity, setSelectedCity] = useState<{ id?: string, name?: string }>({}); 
  const [selectedStatus, setSelectedStatus] =  useState<{ id?: string, name?: string }>({}); 
  const [selectedSkill, setSelectedSkill] =  useState<{ id?: string, name?: string }>({}); 
  const [selectedProgram, setSelectedProgram] =  useState<{ id?: string, name?: string }>({}); 
  const [selectedCompany, setSelectedCompany] =  useState<{ id?: string, name?: string }>({}); 
  const [selectedRole, setSelectedRole] =  useState<{ id?: string, name?: string }>({}); 
  const [selectedShift, setSelectedShift] = useState<{ id?: string, name?: string }>({});
  const [open, setOpen] = useState(false);
  const [endDate, setEndDate] = useState<any>("");
  const [startDate, setStartDate] = useState<any>("");
  const [selectedDates, setSelectedDates] = useState<any>();

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
  } = useMutation('states', getStates);

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
  } = useMutation('cities', getCities);

  const { data: programData, isLoading: isProgramDataLoading } = useQuery(
    'program-list',
    getProgram
  );

  const {
    data: appSkillData,
    isLoading: isGettingskillData,
  } = useQuery('get-skill', getSkills);

  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
  } = useQuery('companies', getCompanies);

  const { data: shiftData,isLoading: isLoadingShifts } = useQuery('shift', getShift);

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  useEffect(() => {
    if (selectedState?.id && selectedState?.name && selectedState) {
      getCityData({ stateId: selectedState?.id, countryId: 1, Take: 1000  });
    }
  }, [selectedState]);

 
  const handleSubmit = () => {
    let startTimes:any[] = []
    let endTimes:any[] = []
    if(page === "Assignment"){
      if(selectedShift && selectedDates?.length > 0 && selectedShift?.id){
        const starttime = shiftData?.Result?.find((shift:any) => shift.Id == selectedShift?.id)?.StartTime.slice(0, 5) || '';
        const endtime = shiftData?.Result?.find((shift:any) => shift.Id == selectedShift?.id)?.EndTime.slice(0, 5) || '';
        selectedDates.forEach((date:any) => {
          const assignmentDate = convertDateToYYYYMMDDForAssignment(date);
          const localUTCdata = convertUTCTimesToLocal(assignmentDate, starttime, endtime);
          const utcConvertedData = convertTimesToUTCForAssignment(assignmentDate,localUTCdata?.startTime ,localUTCdata?.endTime );
          startTimes.push(utcConvertedData?.startTime)
          endTimes.push(utcConvertedData?.endTime)
        });
      }
      else{
        startTimes = selectedDates?.map((data: any) => getStartOfDayUTC(data))
        endTimes = selectedDates?.map((data: any) => getEndOfDayUTC(data))
      }
    }
    const obj = {
      SelectedState: selectedState,
      SelectedCity: selectedCity,
      SelectedStatus: selectedStatus,
      SelectedSkill: selectedSkill,
      SelectedCompany: selectedCompany,
      SelectedRole: selectedRole,
      SelectedStartDate: startDate,
      SelectedEndDate: endDate,
      SelectedDates : selectedDates,
      SelectedShift: selectedShift,
      StartTimes:startTimes,
      EndTimes:endTimes,
      SelectedProgram: selectedProgram
    };
    handleClose(obj, false);
  };

  const handleReset = () => {
    const obj = {
      SelectedState: '',
      SelectedCity: '',
      SelectedStatus: '',
      SelectedSkill: '',
      SelectedCompany: '',
      SelectedRole: '',
      SelectedStartDate: '',
      SelectedEndDate: '',
      SelectedDates:null,
      SelectedShift:'',
      SelectedProgram: ''
    };
    handleClose(obj, false);
  }

  useEffect(() => {
    if (values) {
      setSelectedState(values?.SelectedState ?? '');
      setSelectedCity(values?.SelectedCity ?? '');
      setSelectedStatus(values?.SelectedStatus ?? '');
      setSelectedSkill(values?.SelectedSkill ?? '');
      setSelectedCompany(values?.SelectedCompany ?? '');
      setSelectedRole(values?.selectedRole ?? '');
      setStartDate(values?.selectedStartDate ?? '');
      setEndDate(values?.selectedEndDate ?? '');
      setSelectedDates(values?.SelectedDates ?? null)
      setSelectedShift(values?.SelectedShift ?? '')
      setSelectedProgram(values?.SelectedProgram ?? '')
    }
  }, [values]);

  const handleDateChange = (value: any, date: any) => {
if(date == "startDate"){
  setStartDate(value)
}
else{
  setEndDate(value)
}
  }

  const getRoleName = (roleValue: number): string | undefined => {
    const roleName = Object.keys(RoleEnum).find(
      (key) => RoleEnum[key as keyof typeof RoleEnum] === roleValue
    );
    return roleName;
  };

  
  const StyledDatePicker = styled(DatePicker)`
    border: 1px solid #aa9393;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 13px 12px 12px 30px !important;
    width: 100%;
  `;

  const onChangeDateShift = (dates:any) => {
    setSelectedDates(dates);
  };

  const handleShiftTimeChange = (event:any) => {
    const selectedShiftId = event.target.value;
    const shiftValue = shiftData?.Result?.filter((data: any) => data?.Id == selectedShiftId);
    const { startTime, endTime } = convertUTCTimesToLocal(currentDate, shiftValue[0]?.StartTime, shiftValue[0]?.EndTime)
      const selectedShiftValue : {} = {
      id: shiftValue[0]?.Id,
      name: `${startTime?.slice(0, 5)} - ${endTime?.slice(0, 5)}`
      }
    setSelectedShift(selectedShiftValue)
  };

  return (
    <Popover
      open={showPopup}
      anchorEl={anchorEl}
      onClose={() => handleClose({}, true)}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 197, left: 520 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className="modal-header" style={{ padding: '14px 24px' , borderBottom:"1px solid black"}}>
        <Typography variant="h6">Filter</Typography>
        <div 
        className="btn btn-sm btn-icon btn-active-color-primary"
        onClick={() => handleClose({}, true)} style={{ color: '#FE7253', display: "flex", alignItems:"center", justifyContent:"end", backgroundColor:"white" }}>
        <span className="material-symbols-outlined closeBtn">cancel</span>
        </div>
      </div>
      <div className="p-7">
        {(page === "Nursinghome" || page === "Company" || page === "Assignment") && (
          <>
            <select
              disabled={isLoadingState}
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedState?.id}
              onChange={e => {
                const selectedOption = stateData?.Result?.find((state:any) => state.Id == e.target.value);
                if(selectedOption){
                  setSelectedState({ id: selectedOption?.Id,name:selectedOption?.StateName});
                  setSelectedCity({});
                }
              }}
              style={{ marginBottom: '10px' }}
            >
              <option value="">State</option>
              {stateData?.Result?.map((state: any) => (
                <option value={state?.Id} key={state?.Id}>
                  {state?.StateName} ({state?.Acronym})
                </option>
              ))}
            </select>

            <select
              disabled={isLoadingCity}
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedCity?.id}
              onChange={e => {
                const selectedCity = cityData?.Result?.find((state:any) => state.Id == e.target.value);
                if(selectedCity){
                  setSelectedCity({id:selectedCity?.Id,name:selectedCity?.CityName});
                }
              }}
              style={{ marginBottom: (page === "Company" || page === "Assignment") ? "10px" : "0px" }}
            >
              <option value="">Select City</option>
              {cityData?.Result?.map((city: any) => (
                <option value={city?.Id} key={city?.Id}>
                  {city?.CityName}
                </option>
              ))}
            </select>
          </>
        )}

        {(page === "Company" || page === "Document" || page == 'City') && (
          <select
            className="form-select form-select-solid form-select-lg stateSelect autofillField"
            value={selectedStatus?.id}
            onChange={e => {
              const statusMapping = {
                "true": { id: "true", name: "Active" },
                "false": { id: "false", name: "Inactive" },
              };

              const selectedStatus = statusMapping[e.target.value as keyof typeof statusMapping];
              if (selectedStatus) {
                setSelectedStatus(selectedStatus);
              }

              // const selectedStatus = stateData.find((state:any) => state.Id == e.target.value);
              // if(selectedStatus){
              //   setSelectedStatus(selectedStatus);
              // }
            }}
            style={{ marginBottom: page === "Document" ? "10px" : "0px" }}
          >
            <option value="">Select Status</option>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        )}

        {(page === "ShiftApproval" || page === "Assignment") && (
          <select
            className="form-select form-select-solid form-select-lg stateSelect autofillField"
            value={selectedStatus?.id}
            onChange={e => {
              const statusMapping = {
                "1": { id: "1", name: "Open" },
                "2": { id: "2", name: "Filled" },
                "3": { id: "3", name: "Completed" },
                "4": { id: "4", name: "Approved" },
                "5": { id: "5", name: "Cancelled" }
              };

              const selectedStatus = statusMapping[e.target.value as keyof typeof statusMapping];
              if (selectedStatus) {
                setSelectedStatus(selectedStatus); 
              }
            }}
            style={{ marginBottom: page === "Assignment" ? "10px" : "0px" }}
          >
            <option value="">Select Status</option>
            <option value="1">Open</option>
            <option value="2">Filled</option>
            <option value="3">Completed</option>
            <option value="4">Approved</option>
            <option value="5">Cancelled</option>
          </select>
        )}

        {(page === "Clinician" || page === "Assignment" || page === "ShiftApproval") && (
          <>
            <select
              disabled={isGettingskillData}
              value={selectedSkill?.id}
              className="form-select form-select-solid form-select-lg stateSelect"
              onChange={e => {
                const selectedSkill = appSkillData?.Result?.find((state:any) => state.Id == e.target.value);
                if(selectedSkill){
                  setSelectedSkill({id:selectedSkill?.Id,name:selectedSkill?.SkillName});
                }
              }}
              style={{ marginBottom: "10px", marginTop: page == 'ShiftApproval' ? '10px' : '0px' }}
            >
              <option value="">Select skill</option>
              {appSkillData?.Result?.map((skill: any) => (
                <option value={skill?.Id} key={skill?.Id}>
                  {skill?.SkillName}
                </option>
              ))}
            </select>

            {page === "Clinician" && (
            <select
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedStatus?.id}
              onChange={e => {
                const statusMapping = {
                  "1": { id: "1", name: "Pending" },
                  "2": { id: "2", name: "Approved" },
                  "3": { id: "3", name: "Rejected" },
                  "4": { id: "4", name: "Blocked" },
                  "5": { id: "5", name: "Suspended" },
                  "6": { id: "6", name: "Terminated" }
                };
                const selectedStatus = statusMapping[e.target.value as keyof typeof statusMapping];
                if (selectedStatus) {
                  setSelectedStatus(selectedStatus);
                }
                //setSelectedStatus(e.target.value);
              }}
            >
              <option value="">Select Status</option>
              <option value="1">Pending</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
              <option value="4">Blocked</option>
              <option value="5">Suspended</option>
              <option value="6">Terminated</option>
            </select>
            )}
          </>
        )}

        {(page === "Document" || page === "Assignment" || page === "Admin" || page === "Clinician" || page === "Timesheet") && (
          <select
            disabled={isLoadingCompanies}
            value={selectedCompany?.id}
            className="form-select form-select-solid form-select-lg stateSelect"
            onChange={e => {
              const selectedOption = companiesData?.Result?.find((state:any) => state.Id == e.target.value);
                if(selectedOption){
                  setSelectedCompany({id:selectedOption?.Id,name:selectedOption.CompanyName});
                }
            }}
            style={{ marginTop: page === "Clinician" ? '10px' : '0px'}}
          >
            <option value="">Select facility</option>
            {companiesData?.Result?.map((company: any) => (
              <option value={company?.Id} key={company?.Id}>
                {company?.CompanyName}
              </option>
            ))}
          </select>
        )}
  {(page === "Clinician" || page === "Assignment") && (
          <select
            className="form-select form-select-solid form-select-lg stateSelect mt-3"
            value={selectedProgram?.id}
            onChange={e => {
              const selectedOption = programData?.Result?.find((state:any) => state.Id == e.target.value);
                if(selectedOption){
                  setSelectedProgram({id:selectedOption?.Id,name:selectedOption?.Name});
                }
              //setSelectedProgram(e.target.value);
            }}
          >
            <option value="">Select program</option>
            {programData
              ? programData?.Result?.map((program: any) => (
                  <option value={program?.Id} key={program?.Name}>
                    {program?.Name}
                  </option>
                ))
              : null}
          </select>
  )}

        {page === "Assignment" && (
          <>
            <div className='mt-3'>
              <StyledDatePicker
                showIcon
                selectedDates={selectedDates}
                selectsMultiple
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                placeholderText="MM/DD/YYYY"
                onChange={onChangeDateShift}
                autoComplete="off"
                onInputClick={() => setOpen(true)} 
                onClickOutside={() => setOpen(false)} 
                open={open}
                //excludeDates={formattedAcceptedShiftDate}
              />
            </div>
            <select
              disabled={isLoadingShifts}
              value={selectedShift?.id}
              className="form-select form-select-solid form-select-lg stateSelect mt-3"
              onChange={e => {
                handleShiftTimeChange(e);
              }}
            >
              <option value="">Select Shift</option>
              {shiftData?.Result?.map((shift: any) => {
                const { startTime, endTime } = convertUTCTimesToLocal(currentDate, shift?.StartTime, shift?.EndTime);
                return (
                  <option value={shift?.Id} key={shift?.Id}>
                    {startTime?.slice(0, 5)} - {endTime?.slice(0, 5)}
                  </option>
                )
              })}
            </select>
            
          </>
        )}

        {page === "Admin" && (
          <select
          name="selectedRole"
          value={selectedRole?.id}
          onChange={e => {
            let selectedRoleValue : IdName = { id: "", name: "" }; 

              if (parseInt(e.target.value, 10) === RoleEnum.SuperAdmin) {
                selectedRoleValue ={ id: String(RoleEnum.SuperAdmin),name: String(getRoleName(parseInt(e.target.value, 10) )) };
              } else if ( parseInt(e.target.value, 10) === RoleEnum.CompanyAdmin) {
                selectedRoleValue ={ id: String(RoleEnum.CompanyAdmin),name: String(getRoleName(parseInt(e.target.value, 10) )) };
              } else if ( parseInt(e.target.value, 10) === RoleEnum.Admin) {
                selectedRoleValue = { id: String(RoleEnum.Admin),name: String(getRoleName(parseInt(e.target.value, 10) )) };
              } 

            setSelectedRole(selectedRoleValue);
            //setSelectedProgram(e.target.value);
          }}
         // onChange={e => setSelectedRole(e.target.value)}
          // onBlur={formik.handleBlur}
          className="form-select form-select-solid form-select-lg stateSelect"
          style={{marginTop:"10px"}}
        >
          <option value="">Select Role</option>
          {isSuperAdmin && (
            <option value={RoleEnum?.SuperAdmin}>
              Super Admin
            </option>
          )}
          {(isSuperAdmin || isAdmin) && (
            <option value={RoleEnum?.Admin}>Admin</option>
          )}
          <option value={RoleEnum?.CompanyAdmin}>
            Facility Admin
          </option>
        </select>
        )}
        {page === "Timesheet" && (
          <>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className='mt-2'>
            <label>Start date</label>
          <DatePicker
            name="StartDate"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            disableHighlightToday={true}
            slotProps={{
              textField: {
                size: "small",
                error: false,
              },
            }}
            views={["year", "month", "day"]}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
              },
              border: "1px solid #919EAB",
              borderRadius: "0.475rem !important",
              height: "3.4rem !important",
              padding: "2px 2px !important",
              marginTop:"10px"
            }}
          />
          </div>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className='mt-2'>
        <label>End date</label>
          <DatePicker
            name="EndDate"
            value={endDate}
            disabled={!startDate}
            disableHighlightToday={true}
            onChange={(date) => {
              setEndDate(date);
            }}
            minDate={startDate}
            slotProps={{
              textField: {
                size: "small",
                error: false,
              },
            }}
            views={["year", "month", "day"]}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
              },
              border: "1px solid #919EAB",
              borderRadius: "0.475rem !important",
              height: "3.4rem !important",
              padding: "2px 2px !important",
              marginTop:"10px"
            }}
          />
          </div>
        </LocalizationProvider> */}
         <div className='mt-2'>
        <label>Start date</label>
        <StyledDatePicker
                showIcon
                selected={startDate}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                placeholderText="MM/DD/YYYY"
                onChange={(value) => handleDateChange(value, "startDate")}
                autoComplete="off"
              />{' '}
              </div>
              <div className='mt-2'>
<label>End date</label>
        <StyledDatePicker
                showIcon
                selected={endDate}
                disabled={!startDate}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                minDate={startDate}
                placeholderText="MM/DD/YYYY"
                onChange={(value) => handleDateChange(value, "endDate")}
                autoComplete="off"
              />{' '}
              </div>
              <div className='mt-2'>
        <select
              className="form-select form-select-solid form-select-lg stateSelect autofillField"
              value={selectedStatus?.id}
              onChange={e => {
                const statusMapping = {
                  "1": { id: "1", name: "Pending" },
                  "2": { id: "2", name: "Approved" },
                  "3": { id: "3", name: "Rejected" },
                };
                const selectedStatus = statusMapping[e.target.value as keyof typeof statusMapping];
                if (selectedStatus) {
                  setSelectedStatus(selectedStatus);
                }
               // setSelectedStatus(e.target.value)
              }}
              style={{marginTop:"10px"}}
            >
              <option value="">Select Status</option>
              <option value="2">Approved</option>
              <option value="3">Rejected</option>
              <option value="1">Pending</option>
            </select>
            </div>
            </>
        )}

      </div>
      <div className="w-100 d-flex align-items-center justify-content-between p-5">
        {/* <Button onClick={() => handleClose({})} variant="contained">
          Cancel
        </Button> */}
         <CustomTooltip title={'Reset'}>
                                        <RestoreIcon
                                          style={{
                                            height: '30px',
                                            width: '50%',
                                          }}
                                          onClick={() => handleReset()}
                                        />
                                      </CustomTooltip>
        <div className="w-15px"></div>
        {/* <Button onClick={handleSubmit} variant="contained" color="primary">
          Save
        </Button> */}

<Button
              onClick={handleSubmit}
              className="d-flex align-items-center w-50 justify-content-center"
            >
              Apply
            </Button>
      </div>
    </Popover>
  );
};

export default FilterCommonPopUp;
