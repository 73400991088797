import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { MultiSelect } from 'react-multi-select-component';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import {
  getCompanies,
  getCompaniesWithoutGeneral,
  updateProviderDNRStatus,
} from '../../api';

const modalsRoot = document.getElementById('root-modals') || document.body;

const UpdateClinicianStatusToDNR = ({
  showPopup,
  handleClose,
  editData,
}: any) => {
  const [companydata, setCompanyData] = useState<any>([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const validationSchema = Yup.object().shape({
    selectedCompanies: Yup.array()
      .min(1, 'Select at least one company')
      .required('Select at least one company'),
    reason: Yup.string().required('Please give a reason'),
  });

  const validationSchemaForUpdateDNR = Yup.object().shape({
    selectedCompanies: Yup.array(),
    reason: Yup.string().required('Please give a reason'),
  });

  const chosenValidationSchema =
    editData?.DNRCompanyIds?.length != 0
      ? validationSchemaForUpdateDNR
      : validationSchema;

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'update-clinician-status',
    updateProviderDNRStatus
  );

  const { mutateAsync: getCompaniesByProviderId,data: companiesData, isLoading: isLoadingCompanies } = useMutation(
    'companies',
    getCompaniesWithoutGeneral
  );

  const isClinicianGeneral = (editData?.CompanyProviders?.some((id: any) => id?.CompanyId == 1) && editData?.CompanyProviders?.length == 1)
  useEffect(() => {
    if(isClinicianGeneral){
      getCompaniesByProviderId({})
    }
    else{
      getCompaniesByProviderId({clinicianId: editData?.Id})
    }
  },[editData])

  const formik: any = useFormik({
    initialValues: {
      selectedCompanies: [],
      isChecked: false,
      reason: '',
    },
    validationSchema: chosenValidationSchema,
    onSubmit: async values => {
      let selectedComapnyId = values?.selectedCompanies?.map(
        (item: any) => item?.value
      );
      let bodyParams = {
        providerId: editData?.Id,
        companyIds: selectedComapnyId,
        remarks: values?.reason,
      };
      let response = await addAPI(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response?.Message);
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.Message);
      }
    },
  });

  const handlecompanychange = (fiedname: any, value: any) => {
    formik.setFieldValue('selectedCompanies', value);
  };

  useEffect(() => {
    if (companiesData) {
      const updatedOptions = companiesData?.Result?.map((d: any) => ({
        label: d?.CompanyName,
        value: d?.Id,
      }));

      setDocumentOptions(updatedOptions);
    } else {
      setDocumentOptions([]);
    }
  }, [companiesData]);

  useEffect(() => {
    if (documentOptions && editData?.CompanyProviders?.length > 0) {
      if (editData?.DNRCompanyIds?.length > 0) {
        const clinicianCompanyId = editData?.DNRCompanyIds?.map(
          (id: any) => id
        );
        const ClinicianCompany = documentOptions?.filter((companyid: any) =>
          clinicianCompanyId?.includes(companyid?.value)
        );
        setCompanyData(ClinicianCompany);

        formik.setFieldValue('selectedCompanies', ClinicianCompany);
      } else {
        const clinicianCompanyId = editData?.CompanyProviders?.map(
          (id: any) => id?.CompanyId
        );
        const ClinicianCompany = companiesData?.Result?.filter(
          (companyid: any) => clinicianCompanyId?.includes(companyid?.Id)
        );
        setCompanyData(ClinicianCompany);
      }
    }
  }, [documentOptions, editData?.CompanyProviders]);

  const isCheckboxChecked = formik.values.isChecked;

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '14px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>
            {editData?.DNRCompanyIds?.length > 0 ? `Update ${editData?.FirstName} ${editData?.LastName} DNR` : `${editData?.FirstName} ${editData?.LastName} DNR`}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <div
              className="p-4"
              style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
            >
              <Col xs={12}>
                <label className="form-label fw-bold">
                  Select Facility
                  {editData?.DNRCompanyIds?.length == 0 && (
                    <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
                  )}
                </label>
                <div
                  style={{ border: '1px solid #919eab', borderRadius: '8px' }}
                >
                  <MultiSelect
                    options={documentOptions ?? []}
                    value={formik.values.selectedCompanies ?? []}
                    onChange={(selected: any) =>
                      handlecompanychange('selectedCompanies', selected)
                    }
                    labelledBy="Select facility..."
                    overrideStrings={{
                      allItemsAreSelected: "All facilities are selected",
                      selectSomeItems: 'Select facility...',
                    }}
                    className="selectProgram form-control form-control-lg form-control-solid"
                  />
                </div>
                {formik.touched.selectedCompanies &&
                  formik.errors.selectedCompanies && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.selectedCompanies}
                      </div>
                    </div>
                  )}
              </Col>
              <div className="mt-3">
                <label
                  className="form-label fw-normal"
                  style={{ fontSize: '16px' }}
                >
                  Reason
                  {''}{' '}
                  <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                    *
                  </span>
                </label>
                <textarea
                  rows={4}
                  cols={40}
                  className="form-control form-control-solid mb-3"
                  style={{ border: '1px solid #919EAB' }}
                  placeholder="Write reason here...."
                  {...formik.getFieldProps('reason')}
                />
              </div>
              {/* )} */}
              {formik.touched.reason && formik.errors.reason && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.reason}</div>
                </div>
              )}
              <Row className="mt-8">
                <div className="d-flex align-items-start">
                  <input
                    type="checkbox"
                    id="documentCheckbox"
                    className="me-3 mt-1"
                    {...formik.getFieldProps('isChecked')}
                  />
                  <label
                    htmlFor="documentCheckbox"
                    style={{ cursor: 'pointer', fontSize: '13px' }}
                  >
                    I agree that the user will be DNR for the selected
                    facilities. All assigned shifts relevant to these facilities
                    will be dropped, and the user will no longer receive
                    invitations from the selected facilities
                  </label>
                </div>
              </Row>
              {/* <hr style={{ width: "100%", borderTop: "1px solid #000" }} /> */}
            </div>
            <Row className="p-4" style={{ borderTop: '1px solid #919eab66' }}>
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!isCheckboxChecked}
                >
                  {isAdding ? 'Loading...' : (editData?.DNRCompanyIds?.length > 0 ? 'Update DNR' : 'Confirm DNR')}
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default UpdateClinicianStatusToDNR;
