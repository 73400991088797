import { useFormik } from 'formik';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import { dropAssignments, updateProviderDNRStatusToSuspend } from '../../api';
import { AssignmentStatusEnum } from '../../../../helpers/enum';

const modalsRoot = document.getElementById('root-modals') || document.body;

const UpdateClinicianStatusToSuspend = ({
  showPopup,
  handleClose,
  editData,
}: any) => {
  const isShowSuspension = !editData?.Result?.StatusId;

  const validationSchemaWithDays = Yup.object().shape({
    reason: Yup.string().trim().required('Please give a reason'),
    selectedDays: Yup.string().required('Days selection is required'),
  });

  const validationSchemaWithoutDays = Yup.object().shape({
    reason: Yup.string().trim().required('Please give a reason'),
    selectedDays: Yup.string(),
  });

  const chosenValidationSchema = isShowSuspension
    ? validationSchemaWithDays
    : validationSchemaWithoutDays;

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'clinician-status-suspend',
    updateProviderDNRStatusToSuspend
  );

  const { mutateAsync: dropAssignmentAPI, isLoading: isLoading } = useMutation(
    'clinician-status-suspend',
    dropAssignments
  );

  const formik: any = useFormik({
    initialValues: {
      reason: '',
      selectedDays: '',
      isChecked: false,
    },
    validationSchema: chosenValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      let bodyParams = {
        ProviderId: editData?.Id,
        IsSuspended: true,
        SuspensionDays: values?.selectedDays,
        Remarks: values?.reason ?? '',
      };

      let bodyParamsforDropAssignment = {
        Id: editData?.Result?.AssignmentId,
        Status: AssignmentStatusEnum?.Cancelled,
        Remarks: values?.reason ?? '',
      };

      try {
        let response: any;
        if (!isShowSuspension) {
          response = await dropAssignmentAPI(bodyParamsforDropAssignment);
        } else {
          response = await addAPI(bodyParams);
        }

        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          resetForm();
          handleClose(true);
        } else {
          snackActions.error(response?.Message);
        }
      } catch (error: any) {
        snackActions.error(error ?? 'Something went wrong !!');
      }
    },
  });

  const isCheckboxChecked = formik.values.isChecked;

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '14px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>
            {isShowSuspension ? `Suspend ${editData?.FirstName} ${editData?.LastName} Account` : 'Cancel Assignment'}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <Container fluid>
            <div
              className="p-4"
              style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
            >
              <Row>
                {isShowSuspension ? (
                  <Col xs={12}>
                    <div className="mt-3">
                      <label
                        className="form-label fw-normal"
                        style={{ fontSize: '16px' }}
                      >
                        Suspension Duration (in Days)
                        {''}{' '}
                        <span
                          className="fw-bolder mt-5"
                          style={{ color: 'red' }}
                        >
                          *
                        </span>
                      </label>
                      <select
                        style={{ border: '1px solid #919EAB' }}
                        className="form-select form-select-solid form-select-lg"
                        {...formik.getFieldProps('selectedDays')}
                      >
                        <option value="">Select Days</option>
                        <option value="7">7 days</option>
                        <option value="10">10 days</option>
                        <option value="30">30 days</option>
                        <option value="30">60 days</option>
                      </select>
                      {formik.touched.selectedDays &&
                        formik.errors.selectedDays && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.selectedDays}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                ) : (
                  <div>
                    <h5>
                      Please confirm your decision to cancel your assignments
                      and provide an explanation for your decision.
                    </h5>
                  </div>
                )}
                <Col xs={12}>
                  <div className="mt-3">
                    <label
                      className="form-label fw-normal"
                      style={{ fontSize: '16px' }}
                    >
                      {isShowSuspension
                        ? 'Reason for Suspension'
                        : 'Reason for cancel Assignments'}
                      {''}{' '}
                      <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                        *
                      </span>
                    </label>
                    <textarea
                      rows={4}
                      cols={40}
                      className="form-control form-control-solid mb-3"
                      style={{ border: '1px solid #919EAB' }}
                      placeholder={
                        !isShowSuspension
                          ? 'Write reason here....'
                          : 'Please provide a reason for suspending this user...'
                      }
                      {...formik.getFieldProps('reason')}
                    />
                  </div>
                  {formik.touched.reason && formik.errors.reason && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.reason}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mt-8">
                <div className="d-flex align-items-start">
                  <input
                    type="checkbox"
                    id="documentCheckbox"
                    className="me-3 mt-1"
                    {...formik.getFieldProps('isChecked')}
                  />
                  <label
                    htmlFor="documentCheckbox"
                    style={{ cursor: 'pointer', fontSize: '13px' }}
                  >
                    {!isShowSuspension
                      ? 'I agree that on cancel assignment, all upcoming shifts relevant to this assignment will be cancelled'
                      : 'I agree that once I suspend the user, all allocated upcoming shifts will be dropped during the suspension period, and the user will not be able to perform any actions during this time.'}
                  </label>
                </div>
              </Row>
              {/* <hr style={{ width: "100%", borderTop: "1px solid #000" }} /> */}
            </div>
            <Row className="p-4" style={{ borderTop: '1px solid #919eab66' }}>
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  size="lg"
                  variant="primary"
                  disabled={!isCheckboxChecked}
                >
                  {!isShowSuspension
                    ? isLoading
                      ? 'Loading...'
                      : 'Confirm Cancel Assignment'
                    : isAdding
                    ? 'Loading...'
                    : 'Confirm Suspension'}
                </Button>
              </Col>
            </Row>
          </Container>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default UpdateClinicianStatusToSuspend;
