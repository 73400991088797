import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/table/MuiTable';
import { charValidate } from '../../../../components/utility';
import {
  convertUTCTimesToLocal,
  convertUTCTimesToLocalFuncForCheckInOut,
  formatCharges,
  formatPhoneNumber,
} from '../../../../helpers/commonFunctions';
import { ReactComponent as User } from '../../../assets/svg/user.svg';
import { KTCard } from '../../../helpers';
import { getViewInviteeData } from '../api';
import CustomTooltip from '../utility/CustomTooltip';
import './assignment.css';
import { APP_URLs } from '../../../../constants/appURLs';

const ViewInvite: React.FC = () => {
  const tableRef = useRef();
  const navigate = useNavigate();
  const location: any = useLocation();
  const locationData = location?.state;
  const utcAssignmentDate = locationData?.StartTime.split('T')[0];
  const utcStartTime = locationData?.StartTime.split('T')[1];
  const utcEndTime = locationData?.EndTime.split('T')[1];
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localTime = convertUTCTimesToLocal(
    utcAssignmentDate,
    utcStartTime,
    utcEndTime,
    timezone
  );
  const formattedDate = moment(localTime?.assignmentDate, 'MM-DD-YYYY').format(
    'MM/DD/YYYY'
  );

  let storedLocationData = localStorage.getItem('locationData');
  const locationId = storedLocationData ? JSON.parse(storedLocationData) : null;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [tableOptions, setTableOptions] = useState({
    Id: locationData?.Id ?? 0,
    search: '',
  });

  const {
    mutateAsync: getviewinviteedata,
    isLoading: isLoading,
    data: viewinviteedata,
  } = useMutation('assignment-view', getViewInviteeData);

  useEffect(() => {
    getviewinviteedata(locationData?.Id);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Clinician',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div className='d-flex justify-content-between align-items-center'>
          <div className='me-2'>
            {data.row.ProfilePictureUrl ? (
              <img
                className="clinicianImage"
                src={`${APP_URLs.viewDocBaseURL}/${data.row.ProfilePictureUrl}`}
                alt="API Image"
              />
            ) : (
              <User />
            )}
          </div>
          <div className="invitedUser">
            {data?.row?.FirstName + ' ' + data?.row?.LastName || '-'}
          </div>
        </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'cell-padding',
      renderCell: (data: any) => {
        return <div className="invitedUser">{data?.row?.Email ?? '-'}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Invitation status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const inviteeStatus = data?.row?.IsAccepted

        let backgroundColor = '';
    let statusTextColor = '';
    let statusText = '';

    switch (inviteeStatus) {
      case null:
        backgroundColor = '#F6851F29';
        statusTextColor = '#F6851F';
        statusText = "Pending"
        break;
      case true:
        backgroundColor = '#d4edda';
        statusTextColor = '#155724';
        statusText = "Accepted"
        break;
      case false:
        backgroundColor = '#FF563029';
        statusTextColor = '#FF5630';
        statusText = "Rejected"
        break;

      default:
        backgroundColor = '';
        statusTextColor = '';
        statusText = ''
        break;
    }
        return <div
        className="d-flex align-items-center stsusDatadesign"
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
          {statusText}
        </span>
      </div>;
      },
    },
    {
      field: 'contact',
      headerName: 'Contact',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div className="invitedUser">{data?.row?.MobileNumber ?? '-'}</div>
        );
      },
    },
  ];

  const handleBackClick = () => {
    navigate('/view-assignment-shift', { state: locationId });
  };

  const getStatusStyles = (status: string) => {
    let backgroundColor = '';
    let statusTextColor = '';

    switch (status) {
      case 'Opened':
        backgroundColor = '#00786729';
        statusTextColor = '#007867';
        break;
      case 'Filled':
        backgroundColor = '#FF563029';
        statusTextColor = '#FF5630';
        break;
      case 'Rejected':
        backgroundColor = '#F6851F29';
        statusTextColor = '#F6851F';
        break;

      default:
        backgroundColor = '';
        statusTextColor = '';
        break;
    }

    return { backgroundColor, statusTextColor };
  };

  const { backgroundColor, statusTextColor } = getStatusStyles(
    locationData?.Status
  );

  const viewInvitedData = viewinviteedata?.Result;
  const viewInvitedPatientData = viewInvitedData?.PatientDetails;

  return (
    <div>
      <Col xs={6}>
        <div className="mb-5 backArrow" onClick={handleBackClick}>
          <ArrowBackIcon />
        </div>
      </Col>

      <div className="position-relative">
        <div className="row">
          <div className="col-xl-4">
            <div className="shiftDetail-sticky card p-4 customeboxShadow">
              <div className="d-flex justify-content-between my-4 pb-3 inviteDetailTitle">
                <h3>{viewInvitedData?.AssignmentCode}</h3>
                <span className="fw-normal titleFont">
                  {viewInvitedData?.CompanyName}
                </span>
              </div>
              <div className="mt-2 mb-4 pb-3">
                <div className="row">
                  <div className="col-6">
                    <h5>Patient Info:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p className="mb-2">
                        {viewInvitedPatientData &&
                        viewInvitedPatientData?.FirstName
                          ? viewInvitedPatientData?.FirstName +
                            ' ' +
                            viewInvitedPatientData?.LastName
                          : 'Loading...'}
                      </p>
                      <p className="mb-2">
                        {viewInvitedPatientData?.ContactNumber &&
                        viewInvitedPatientData?.CountryCode
                          ? `+${formatPhoneNumber(
                              viewinviteedata.Result.PatientDetails
                                .CountryCode +
                                viewinviteedata.Result.PatientDetails
                                  .ContactNumber
                            )}`
                          : ''}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5>Secondary Info:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p className="mb-2">
                        {viewInvitedPatientData?.ContactPersonFirstName ||
                        viewInvitedPatientData?.ContactPersonLastName
                          ? `${viewInvitedPatientData?.ContactPersonFirstName || ''} ${viewInvitedPatientData?.ContactPersonLastName || ''}`
                          : '-'}
                      </p>
                      <p className="mb-2">
                        {viewInvitedPatientData?.ContactPersonContactNumber &&
                        viewInvitedPatientData?.CountryCode
                          ? `+${formatPhoneNumber(
                              viewinviteedata.Result.PatientDetails
                                .CountryCode +
                                viewinviteedata.Result.PatientDetails
                                  .ContactPersonContactNumber
                            )}`
                          : ''}
                      </p>
                    </div>
                  </div>
                  {/* <div className="col-6">
                    <h5>Scheduled Time:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p className="mb-2">
                        {`${convertUTCTimesToLocalFuncForCheckInOut(
                          viewInvitedData?.StartTime,
                          viewInvitedData?.StartTime,
                          viewInvitedData?.EndTime,
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                          'checkIn'
                        )} - ${convertUTCTimesToLocalFuncForCheckInOut(
                          viewInvitedData?.StartTime,
                          viewInvitedData?.StartTime,
                          viewInvitedData?.EndTime,
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                          'clockOut'
                        )}`}
                      </p>
                    </div>
                  </div> */}
                  <div className="col-6">
                    <h5>Weekdays Rate:</h5>
                    <h5>Weekend Rate:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p className="mb-2">
                        {formatCharges(viewInvitedData?.RatePerHour)}
                      </p>
                      <p className="mb-2">
                        {formatCharges(viewInvitedData?.WeekOffRate)}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5>Location:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p className="mb-2">
                        {viewInvitedData && viewInvitedData?.AddressLine1
                          ? viewInvitedData?.AddressLine1 +
                            ', ' +
                            viewInvitedData?.City +
                            ', ' +
                            viewInvitedData?.ZipCode +
                            ', ' +
                            viewInvitedData?.State
                          : 'Loading...'}
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5>Instructions:</h5>
                  </div>
                  <div className="col-6">
                    <div className="viewInviteDetails">
                      <p>
                        <CustomTooltip
                          title={
                            viewInvitedData?.Instruction ||
                            'No instructions available'
                          }
                        >
                          <p>
                            {viewInvitedData?.Instruction
                              ? charValidate(viewInvitedData?.Instruction, 100)
                              : '-'}
                          </p>
                        </CustomTooltip>
                      </p>
                    </div>
                  </div>
                  <div className="col-6">
                    <h5>Other Info:</h5>
                  </div>
                  <div className="col-6">
                    {viewInvitedPatientData?.PatientOtherInformations ? (
                      <ul>
                        {viewinviteedata.Result.PatientDetails.PatientOtherInformations.map(
                          (otherinfo: any, index: any) => (
                            <li className="fw-normal" key={index}>
                              {otherinfo?.OtherInformationDescription}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <h5 className="fw-normal">N/A</h5>
                    )}
                  </div>
                  <div className="col-6">
                    <h5>Notes:</h5>
                  </div>
                  <div className="col-6">
                    <p>
                      <CustomTooltip
                        title={
                          viewInvitedPatientData?.Note || 'No notes available'
                        }
                      >
                        <h1 className="noteDescription">
                          {viewInvitedPatientData?.Note
                            ? charValidate(viewInvitedPatientData?.Note, 100)
                            : '-'}
                        </h1>
                      </CustomTooltip>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <Card className="p-6 customeboxShadow">
              <>
                <div className="d-flex justify-content-between align-items-center inviteDetailCard">
                  <div className='d-flex align-items-center'>
                  <label className='fw-bolder'>Schedule Date: {" "}</label><span style={{ marginLeft: '5px' }}>{formattedDate}</span>
                  <label className='fw-bolder' style={{ marginLeft: '10px' }}>Schedule time: {" "}</label><span style={{ marginLeft: '5px' }}>{`${convertUTCTimesToLocalFuncForCheckInOut(
                          viewInvitedData?.StartTime,
                          viewInvitedData?.StartTime,
                          viewInvitedData?.EndTime,
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                          'checkIn'
                        )} - ${convertUTCTimesToLocalFuncForCheckInOut(
                          viewInvitedData?.StartTime,
                          viewInvitedData?.StartTime,
                          viewInvitedData?.EndTime,
                          Intl.DateTimeFormat().resolvedOptions().timeZone,
                          'clockOut'
                        )}`}
                        </span>
                      </div>
                  <label
                    style={{
                      color: statusTextColor,
                      fontSize: '16px',
                      borderRadius: '5px',
                      backgroundColor: backgroundColor,
                    }}
                  >
                    {locationData?.Status}
                  </label>
                </div>
                <KTCard>
                  <MuiTable
                    addLabel="Add New Clinician"
                    columns={columns}
                    isAddEnable={false}
                    loading={isLoading}
                    data={viewInvitedData?.Invitees}
                    tableRef={tableRef}
                    isRowClickable={true}
                    tableOptions={tableOptions}
                    hideFooterPagination={true}
                  />
                </KTCard>
              </>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewInvite;
