import { useFormik } from 'formik';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import * as Yup from 'yup';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { editStaff } from '../../../layout/components/api';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  userInfo: {
    Id: number;
    FirstName: string;
    LastName: string;
    Email: string;
    MobileNumber: string;
    Roles: { RoleId: number; RoleName: string }[];
    UserCompanies: any[];
  } | null;
};

const EditProfile = ({ show, handleClose, userInfo }: Props) => {
  const userInfoString = localStorage.getItem('userInfo');
  const userInfoData = userInfoString ? JSON.parse(userInfoString) : null;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .required('Email is required'),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .min(10, 'Mobile number format is incorrect'),
    selectedRole: Yup.string().required('Role is required'),
    selectedCompanies: Yup.array().when('selectedRole', ([type], schema) => {
      return type === 'Facility Admin'
        ? schema.required('Select at least one facility')
        : schema.notRequired();
    }),
  });

  const formik: any = useFormik({
    initialValues: {
      firstName: userInfoData?.FirstName,
      lastName: userInfoData?.LastName,
      email: userInfoData?.Email,
      mobileNumber: userInfoData?.MobileNumber,
      selectedCompanies:
        userInfoData?.UserCompanies?.map((company: any) => ({
          label: company.CompanyName,
          value: company.CompanyId.toString(),
        })) || [],
      selectedRole:
        userInfoData?.Roles && userInfoData?.Roles.length > 0
          ? userInfoData.Roles[0].RoleName
          : '',
      Role: '',
    },

    validationSchema,

    onSubmit: async values => {
      const ids =
        values.selectedCompanies.length !== 0
          ? values.selectedCompanies.map((company: any) => company.value)
          : userInfoData?.UserCompanies?.map((company: any) =>
              company.CompanyId.toString()
            ) || [];
      const idsString = ids?.join(',');
      let role = formik.values.selectedRole;
      let slicedvalueMobileNo = values?.mobileNumber?.slice(1);
      let bodyParams: any = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        mobileNumber: slicedvalueMobileNo,
        countryCode: 1,
        roleIds: '1',
      };
      
      if (userInfoData) {
        bodyParams.id = userInfoData.Id;

        let response = await editProfileAPI(bodyParams);
        if (response?.IsSuccess) {
          const userInfoString = localStorage.getItem('userInfo');

          let userInfoData = userInfoString ? JSON.parse(userInfoString) : {};

          userInfoData.FirstName = values.firstName;
          userInfoData.LastName = values.lastName;
          userInfoData.MobileNumber = values.mobileNumber;

          userInfoData.UserCompanies = values.selectedCompanies.map(
            (company: any) => ({
              CompanyId: company.value,
              CompanyName: company.label,
            })
          );

          const updatedUserInfoString = JSON.stringify(userInfoData);

          localStorage.setItem('userInfo', updatedUserInfoString);

          snackActions.success(response?.Message);
          handleClose(false);
        }
      }
    },
  });

  const { mutateAsync: editProfileAPI, isLoading: isEditing } = useMutation(
    'edit-profile',
    editStaff
  );

  const handlePhoneChange = (value: any, country: any) => {
    const countryCode = country.dialCode;
    formik.setFieldValue('countryCode', countryCode);
    formik.setFieldValue('mobileNumber', value);
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-750px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '2px solid #919EAB66',
            padding: '15px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>Edit Profile</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <div className="p-4 mt-2" style={{ backgroundColor: '#f9f9f9' }}>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Row className="gy-5">
              <Col sm={6} xs={12}>
                  <label className="form-label fw-normal inputTitle">
                    First Name
                    <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                    placeholder="First Name"
                    aria-label="First Name"
                    style={{
                      border: '1px solid #919EAB66',
                    }}
                    {...formik.getFieldProps('firstName')}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.firstName}
                      </div>
                    </div>
                  )}
              </Col>

              <Col sm={6} xs={12}>
                  <label className="form-label fw-normal inputTitle">
                    Last Name
                    <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                    placeholder="Last Name"
                    aria-label="Last Name"
                    style={{
                      border: '1px solid #919EAB66',
                    }}
                    {...formik.getFieldProps('lastName')}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.lastName}
                      </div>
                    </div>
                  )}
              </Col>

              <Col sm={6} xs={12}>
                  <label className="form-label fw-normal inputTitle">
                    Email
                    <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
                  </label>
                  <input
                    placeholder="Email"
                    aria-label="Email"
                    {...formik.getFieldProps('email')}
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                    type="email"
                    name="email"
                    autoComplete="off"
                    disabled={userInfo ? true : false}
                    style={{
                      cursor: userInfo ? 'not-allowed' : 'initial',
                      backgroundColor: userInfo ? '#e9ecef' : '#f9f9f9',
                      border: '1px solid #919EAB66',
                    }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  )}
              </Col>

              <Col sm={6} xs={12}>
                  <label className="form-label fw-normal inputTitle">
                    Contact Number 
                    <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
                  </label>
                  <PhoneInput
                        country={'us'}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        onChange={(value: string, country: any) => {
                          const contactNumber = value.replace(
                            country?.dialCode,
                            ''
                          );
                          formik.setFieldValue(
                            'mobileNumber',
                            `${country?.dialCode}${contactNumber}`
                          );
                        }}
                        inputStyle={{
                          width: '100%',
                        }}
                        inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        disableDropdown={false}
                        disableCountryCode={false}
                        onlyCountries={['us']}
                    />
                  {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.mobileNumber}
                        </div>
                      </div>
                    )}
              </Col>
            </Row>
            <div className="w-100 d-flex pt-5 pb-5 align-items-end justify-content-end">
              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{ padding: '7px 45px' }}
              >
                {isEditing ? 'Loading...' : 'Update'}
              </Button>
            </div>
          </form>
        </div>
      </Card>
    </Modal>
  );
};
export default EditProfile;
