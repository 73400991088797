import React, { useCallback, useEffect, useRef, useState } from "react";
import './notificationmodal.css'
import { getNotificationList,deleteNotification, readNotification } from "../api";
import { useMutation } from "react-query";
import { formatNotificationDate } from "../../../../helpers/commonFunctions";
import { Delete } from "@mui/icons-material";
import ConfirmEventPopup from "../common/ConfirmEventPopup";
import { snackActions } from "../../../../helpers/SnackUtilsConfigurator";
import { useNavigate } from "react-router-dom";
import { setNotificationCount } from "../../../../store/slice/header/headerSlice";
import { useDispatch } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

interface ModalProps {
    show: boolean;
    onClose: () => void;
  }

const NotificationList: React.FC<ModalProps> = ({ show, onClose }) => {

    const [notifications, setNotifications] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [tableOptions, setTableOptions] = useState({
        offset: 0,
        limit: 10,
        totalRowCount: undefined,
        search: ''
    });
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const [selectedNotification, setSelectedNotification] = useState<any>(null);
    const navigate = useNavigate();
    const {
        mutateAsync: getNotificationsData,
        data: notificationsData,
    } = useMutation('notification-list', getNotificationList);

    const { mutateAsync: deleteNotificationData,isLoading: isDeleteNotification } =
    useMutation('delete-document', deleteNotification);

    const { mutateAsync: readNotificationData,isLoading: isReadNotification} =
    useMutation('read-notification', readNotification);

    useEffect(() => {
        if(show){
            getNotifications({
                ...tableOptions,
            });
        }
    }, [show]);

    const getNotifications = async (
        query:
          | {
              offset: number | string;
              limit: number;
              search: string;
            }
          | undefined
      ) => {
        let response = await getNotificationsData(query);
        if (response?.IsSuccess) {
            setNotifications((prev) => [...prev, ...response.Result.Notifications]);
            dispatch(setNotificationCount(response.Result?.UnreadNotificationCount))
            setTableOptions((prevState: any) => {
                return {
                    ...prevState,
                    offset: response?.PageDetail?.Skip,
                    limit: response?.PageDetail?.Take,
                    hasNextPage: response?.PageDetail?.HasNext,
                    hasPrevPage: response?.PageDetail?.HasPrevious,
                    totalRowCount: response?.PageDetail?.Count,
                };
            });
        }
    };

    const lastNotificationElementRef = useCallback(
        (node: HTMLDivElement) => {
            if (observer.current) observer.current.disconnect();
            
            observer.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        const updateOffset = tableOptions.offset + tableOptions.limit;
                        const query = {
                            offset: updateOffset,
                            limit: 10,
                            search: ''
                        };
                        if (tableOptions.totalRowCount) {
                            if (tableOptions.totalRowCount > updateOffset) { 
                                getNotifications(query);
                            }
                        }
                    }
                },
                { threshold: 1.0 }
            );
    
            if (node) observer.current.observe(node);
        },
        [tableOptions]
    );

    const handleCloseDelete = () => {
        setShowDeleteConfirmModal(false);
        setSelectedNotification(null)
    };

    const handleOpenDelete = (notification:any) => {
        setShowDeleteConfirmModal(true);
        setSelectedNotification(notification)
    };

    const deleteNotificationApi = async () => {
        if(selectedNotification){
            const response = await deleteNotificationData({ ids: [selectedNotification?.Id] });
            if (response) {
                setNotifications([])
                snackActions.success(response?.Message);
                handleCloseDelete();
                let query = {
                    offset: tableOptions?.offset ?? 0,
                    limit: tableOptions?.limit ?? 10,
                    search: '',
                };
                getNotifications(query);
            }
        }
    };

    const readNotificationApi = async (isAllRead:boolean,notficationId:number) => {
        const response = await readNotificationData({isAllRead:isAllRead, id: notficationId });
        if (response) {
            setNotifications([])
            snackActions.success(response?.Message);
            handleCloseDelete();
            let query = {
                offset: tableOptions?.offset ?? 0,
                limit: tableOptions?.limit ?? 10,
                search: '',
            };
            getNotifications(query);
        }
    };

    const handleNotificationRedirection = (rowData:any) =>{
        if(!rowData?.IsRead){
            readNotificationApi(false,rowData?.Id)
        }
        if(rowData?.NotificationTypeName === 'NoShowAssignmentSchedule'){
            if(rowData?.Data && rowData?.Data?.AssignmentId){
                onClose();
                navigate('/shifts-detail', { state: { assignmentdata: rowData?.Data } });
            }
        }else if(rowData?.NotificationTypeName === 'ProviderDocumentExpiration' || rowData?.NotificationTypeName === 'ProviderDocumentReupload' || rowData?.NotificationTypeName === 'ProviderSkillExpiration'){
            if(rowData?.Data && rowData?.Data?.ProviderId){
                onClose();
                navigate('/clinician', { state: { providerdata: rowData?.Data } });
            }
        }else if(rowData?.NotificationTypeName === 'ProviderRegisteration' || rowData?.NotificationTypeName === 'ProviderSkillReupload'){
            if(rowData?.ReferenceId){
                onClose();
                navigate('/clinician', { state: { providerdata: {ProviderId:rowData?.ReferenceId} } });
            }
        }
    }

    return (
      <div className={`modal fade ${show ? "show d-block" : "d-none"}`} tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-xl modal-dialog-right">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Notification</h5>
            </div>
            <div className="modal-body">
                {(isReadNotification || isDeleteNotification) && (
                    <Box className="spinner-loader">
                        <CircularProgress />
                    </Box>
                )}
                {notifications?.length === 0 ? (
                    <p className="text-center">No new notifications</p>
                ) : (
                    notifications?.map((item:any,index) => (
                    <div 
                        key={item.id} 
                        ref={index === notifications.length - 1 ? lastNotificationElementRef : null}
                        className={`notification-item ${!item.IsRead ? "bgForRead" : ""}`}>
                        <div className="notification-content cursor-pointer" onClick={() => handleNotificationRedirection(item)}>
                            <div>
                                <strong>{item.CreatedOn ? formatNotificationDate(item.CreatedOn) : ''}</strong>
                            </div>
                            <strong>{item.Title}</strong>
                            <p>{item.Description}</p>
                        </div>
                        <div className="text-danger cursor-pointer" onClick={() => handleOpenDelete(item)}>
                            <Delete />
                        </div>
                    </div>
                    ))
              )}
            </div>
            <div className="modal-footer">
                <button className="btn btn-secondary" onClick={onClose}>Close</button>
                <button className="btn btn-primary" onClick={() => readNotificationApi(true,0)}>Mark All as Read</button>
            </div>
          </div>
        </div>
        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() => deleteNotificationApi()}
            loading={false}
            title={'Notification'}
            message={`Are you sure, do you want to remove this Notification?`}
          />
        )}
      </div>
    );
  };
  
  export default NotificationList;