import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { ReactComponent as Visibility } from '../../../assets/svg/visibility.svg';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import {
  documentData,
  editdocumentData,
  getCompanyName,
  uploadPicture,
} from '../api';

import '../Clinician/clinician-style.css';
import './document.css';
import { APP_URLs } from '../../../../constants/appURLs';

type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  editData: {
    IsActive: boolean;
    Documents: any;
    Id: number;
    DocumentName: string;
    DocumentUrl: string;
    CompanyName: string;
    CompanyId: number;
    FileName: string;
  };
};

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditDocument = ({
  show,
  handleClose,
  editData,
  title,
  isForEditProfile = false,
}: Props) => {
  let imgUrl: string = editData?.DocumentUrl
    ? editData?.DocumentUrl
      ? editData?.DocumentUrl
      : ''
    : '';

  const [documentImgURL, setDocumentImgURL] = useState<string>('');
  const [documentUrl, setDocumentUrl] = useState('');
  const [isActive, setIsActive] = useState(true);

  const [imgFileName, setImgFileName] = useState<any>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );
  const [FileName, setFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );
  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Facility name is required'),
    documentName: Yup.string().required('Document name is required'),
    file: Yup.mixed().required('Document is required'),
  });

  const {
    data: companyData,
    isLoading: isLoadingCountry,
    error: stateAPIError,
  } = useQuery('states', getCompanyName);

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'add-document',
    documentData
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    'edit-document',
    editdocumentData
  );

  const formik: any = useFormik({
    initialValues: {
      companyName: editData?.CompanyName,
      documentName: editData?.DocumentName,
      file: editData?.DocumentUrl,
      filename: editData?.FileName,
      isActive: false,
    },
    validationSchema,
    onSubmit: async values => {
      if (!values.file) {
        snackActions.error('Please upload a document.');
        return;
      }
      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        companyId: values?.companyName ?? '',
        documentName: values?.documentName ? values?.documentName?.trim().replace(/\s+/g, ' ') : '',
        documentUrl: documentImgURL ?? '',
        fileName: FileName,
        isActive: isActive,
      };
      let response = editData?.Id
        ? await editAPI(bodyParams)
        : await addAPI(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response?.Message ?? 'Sucess');
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.Message ?? 'Something went wrong!');
      }
    },
  });

  useEffect(() => {
    if (editData) {
      setDocumentUrl(editData.DocumentName);
      setImgFileName(editData.FileName);
      setFileName(editData.FileName);
      setDocumentImgURL(editData.DocumentUrl);
      formik.setFieldValue('companyName', editData.CompanyId);
      formik.setFieldValue('documentName', editData.DocumentName);
      setIsActive(editData.IsActive);
    }
  }, [editData]);

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setFileName(file?.name ?? '');
    setImgFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        setDocumentImgURL(response?.Result[0].FileUrl);
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const handleRemoveFile = () => {
    formik.setFieldValue('file', '');
    setImgFileName('');
  };

  const handleIsAdminChange = (e: any) => {
    setIsActive(e.target.checked);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="addDocumentpopup">
        {isLoadingDoc && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Documents</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <div className="addDocument">
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Container fluid className="p-0">
              <div className="p-6 pb-0">
                <Row>
                  <label className="form-label fw-bold">
                    Facility{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <Col xs={12}>
                    <div className="mb-3">
                      <select
                        disabled={editData ? true : isLoadingCountry}
                        className="form-select form-select-solid form-select-lg documentBorder"
                        {...formik.getFieldProps('companyName')}
                      >
                        <option value="">Select Facility</option>
                        {companyData &&
                          companyData.Result.map((company: any) => (
                            <option value={company.Id} key={company.Id}>
                              {company.CompanyName}
                            </option>
                          ))}
                      </select>

                      {formik.touched.companyName &&
                        formik.errors.companyName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.companyName}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                  <label className="form-label fw-bold">
                    Document Name{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>

                  <Col xs={12}>
                    <div className="mb-5">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 documentBorder"
                        placeholder="Document Name"
                        aria-label="Document Name"
                        {...formik.getFieldProps('documentName')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.documentName &&
                        formik.errors.documentName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.documentName}
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      File <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <div>
                      {imgFileName ? (
                        <div
                          className="d-flex align-items-center"
                          style={fileInputWithDataStyle}
                        >
                          <span>{imgFileName}</span>
                          <div className='d-flex'>
                          <span
                              className="ms-3 me-2"
                              onClick={() =>
                              {
                                  const baseURL = APP_URLs?.viewDocBaseURL;
                                  window.open(`${baseURL}/${documentImgURL}`, '_blank');
                              }}
                            >
                              <Visibility />
                            </span>
                          <span
                            className="material-symbols-outlined"
                            style={{ color: '#FE7253', cursor: 'pointer' }}
                            onClick={handleRemoveFile}
                          >
                            cancel
                          </span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx,image/*"
                            onChange={e => {
                              const selectedFile = e.currentTarget.files?.[0];
                              if (selectedFile) {
                                formik.setFieldValue('file', selectedFile);
                                handleChangeImageUpload(e);
                              }
                            }}
                            className="rounded form-control-file chooseFile"
                          />
                          {formik.touched.documentURL &&
                            formik.errors.documentURL && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.documentURL}
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {formik.touched.file && formik.errors.file && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.file}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="mt-5 d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="form-check-input documentCheckbox"
                    checked={isActive}
                    onChange={handleIsAdminChange}
                  />
                  <label className="form-check-label documentCheckboxText">
                    Is Active?
                  </label>
                </div>
              </div>

              <div className="w-100 d-flex align-items-end justify-content-end mt-4 px-6 py-4 documentFooter">
                <div>
                  <Button
                    type="submit"
                    size="lg"
                    variant="primary"
                    className="documentsaveBtn"
                  >
                    {isAdding || isEditing ? 'Loading...' : 'Save'}
                  </Button>
                </div>
              </div>
            </Container>
          </form>
        </div>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditDocument;

const fileInputWithDataStyle = {
  backgroundColor: '#10355812',
  border: '1px solid #919eab',
  borderRadius: '5px',
  padding: '10px 15px',
  justifyContent: 'space-between',
};
