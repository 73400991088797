import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useEffect, useState } from 'react';
import { Card, Col, Container } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  initializeStep,
  resetAssignmentFormData,
  setAssignmentFormData,
} from '../../../../store/slice/form/assignment_form';
import AddEditAssignmentDocument from '../addeditassignment/addAssignmentDocument';
import { getAssignmentById, getCompanyName } from '../api';
import { Step_0 } from './steps/step0';
import { Step_1 } from './steps/step1';
import { Step_2 } from './steps/step2';
import { Step_3 } from './steps/step3';
import Step_4 from './steps/step4';
import './steps/stepper-style.css';
import { convertDateToYYYYMMDDForAssignment, convertUTCTimesToLocal, formatTimeForCheckInOut } from '../../../../helpers/commonFunctions';
import moment from 'moment';
import { ReactComponent as CreateShift } from "../../../assets/svg/create_shift.svg";
import { ReactComponent as Assignment } from "../../../assets/svg/assignment.svg";
import { ReactComponent as AddPatient } from "../../../assets/svg/add_patient.svg";
import { ReactComponent as FindClinician } from "../../../assets/svg/find_clinician.svg";
import './assignment.css';
import dayjs from 'dayjs';
import ConfirmEventPopup from '../common/ConfirmEventPopup';

interface Document {
  value?: any;
  documentName: string;
  documentUrl: string;
  fileName: string;
}

const Addnewassignment = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const step: number = useSelector((state: any) => state.assignment.step);

  const selectedrowdata = location.state?.assignmenteditdata || [];
  const isCreateShift = location?.state?.isFromViewShift || false
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [showConfirmationPopUp, setShowConfirmationPopUp] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { data: companyData } = useQuery('company-name', getCompanyName);
  const [companyNamesData, setCompanyNamesData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyData?.Result) {
      setCompanyNamesData(companyData?.Result);
    }
  }, [companyData]);

  const {
    data: AssignmentEditDataValues,
    mutateAsync: getAssignmentEditDataValues,
    isLoading: gettingAssignmentValues,
  } = useMutation('editassignment', getAssignmentById);

  useEffect(() => {
    const fetchAndSetAssignmentData = async () => {
      if (selectedrowdata && selectedrowdata?.Id) {
        const response = await getAssignmentEditDataValues({
          id: selectedrowdata.Id,
        });
        if (response?.IsSuccess) {
          // const utcAssignmentDate =
          //   response?.Result?.AssignmentSchedules?.[0]?.StartTime?.split(
          //     'T'
          //   )[0];
          // const utcStartTime =
          //   response?.Result?.AssignmentSchedules?.[0]?.StartTime.split('T')[1];
          // const utcEndTime =
          //   response?.Result?.AssignmentSchedules?.[0]?.EndTime.split('T')[1];
          // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          // const localTime = convertUTCTimesToLocal(
          //   utcAssignmentDate,
          //   utcStartTime,
          //   utcEndTime,
          //   timezone
          // );

          const convertedAssignmentDates = response?.Result?.AssignmentSchedules.map((x:any)=>convertUTCTimesToLocal(convertDateToYYYYMMDDForAssignment(x.StartTime),formatTimeForCheckInOut(x.StartTime),formatTimeForCheckInOut(x.EndTime)));
          const selectedAssignmentDates = convertedAssignmentDates.map((x:any)=>x.assignmentDate);
          const uniqueDates = Array.from(new Set(selectedAssignmentDates));
          const updatedAssignmentSchedules:any[] = []

          uniqueDates.forEach((assignmentDate: any) => {
            const timeSlots:any[] = []
            const formattedAssignmentDate = convertDateToYYYYMMDDForAssignment(assignmentDate)
            response?.Result?.AssignmentSchedules.map((item: any) => {
              const localUTCData = convertUTCTimesToLocal(convertDateToYYYYMMDDForAssignment(item.StartTime), formatTimeForCheckInOut(item.StartTime),formatTimeForCheckInOut(item.EndTime) );
              if(assignmentDate === localUTCData.assignmentDate){
                  const timeSlot = {
                    Id : item.Id,
                    startTime: dayjs(formattedAssignmentDate + 'T' +localUTCData?.startTime),
                    endTime: dayjs(formattedAssignmentDate + 'T' +localUTCData?.endTime),
                    type: item.IsCustomShiftTime ? 'custom' : 'default',
                    isCustomShiftTime: item.IsCustomShiftTime, 
                    invitedUserIds: item.InvitedUserIds,
                    shiftId:item.ShiftId
                  }
                  timeSlots.push(timeSlot)
                }
            });
            const assignmentSchedule = {
              assignmentDate:formattedAssignmentDate,
              timeSlots : timeSlots
            }
            updatedAssignmentSchedules.push(assignmentSchedule)
          });
          const dispatchParams = {
            Id: response?.Result?.Id,
            assignmentCode: response?.Result?.AssignmentCode,
            assignmentName: response?.Result?.AssignmentName,
            companyId: response?.Result?.CompanyId,
            companySkillIds: response?.Result?.CompanySkillIds,
            instruction: response?.Result?.Instruction,
            isRequiredProviderDocumentUpload:
              response?.Result?.IsRequiredProviderDocumentUpload,
            companyDocumentIds: response?.Result?.CompanyDocumentIds ?? [],
            assignmentDocuments: response?.Result?.AssignmentDocuments,
            patientDetails: {
              id: response?.Result?.PatientDetails?.Id ?? 0,
              patientCode: response?.Result?.PatientDetails?.PatientCode ?? '',
              firstName: response?.Result?.PatientDetails?.FirstName ?? '',
              lastName: response?.Result?.PatientDetails?.LastName ?? '',
              countryCode: 1,
              contactNumber:
                response?.Result?.PatientDetails?.ContactNumber ?? '',
              contactPersonFirstName:
                response?.Result?.PatientDetails?.ContactPersonFirstName ?? '',
              contactPersonLastName:
                response?.Result?.PatientDetails?.ContactPersonLastName ?? '',
              contactPersonContactNumber:
                response?.Result?.PatientDetails?.ContactPersonContactNumber ??
                '',
              patientOtherInformationIds:
                response?.Result?.PatientDetails?.PatientOtherInformationIds,
              note: response?.Result?.PatientDetails?.Note ?? '',
            },
            selectLocationType:
              response?.Result?.LocationTypeId == 2 ? '2' : '1',
            addressLine1: response?.Result?.AddressLine1,
            addressLine2: response?.Result?.AddressLine2,
            cityId: response?.Result?.CityId,
            stateId: response?.Result?.StateId,
            zipCode: response?.Result?.ZipCode,
            //shiftId: response?.Result?.ShiftId,
            ratePerHour: response?.Result?.RatePerHour,
            weekendrate: response?.Result?.WeekOffRate,
            assignmentSchedules: updatedAssignmentSchedules,
            clinicianshiftdetails: updatedAssignmentSchedules,
            nursingHomeId: response?.Result?.NursingHomeId,
            programId: response?.Result?.ProgramId,
            //isCustomShiftTime: response?.Result?.IsCustomShiftTime,
          };
          dispatch(setAssignmentFormData(dispatchParams));
          dispatch(initializeStep({ isCreateShift }));
        }
      }
    };

    fetchAndSetAssignmentData();
  }, [selectedrowdata?.Id]);

  const handleBackClick = () => {
    if(isShowConfirmationPopUp){
    setShowConfirmationPopUp(isShowConfirmationPopUp)
    }
    else{
    if(isCreateShift){
      dispatch(resetAssignmentFormData());
    navigate('/view-assignment-shift', {state : selectedrowdata});
    }
    else{
      dispatch(resetAssignmentFormData());
      navigate('/assignment');
    }
  }
  };

  const handleClosedocumentmodal = (value: any) => {
    setShowAddDocumentModal(false);
  };

  const handleCallBack = (value: any) => {
    setShowAddDocumentModal(false);
    const newDocument: Document = {
      documentName: value?.documentName,
      documentUrl: value?.documentUrl,
      fileName: value?.fileName,
    };
  };

  const formDatas: any = useSelector((state: any) => state.assignment.formData);
  const isShowConfirmationPopUp = formDatas?.isInitialPageHaveValues;

  const handleCloseConfirmationPopUp = () => {
    setShowConfirmationPopUp(false)
  }

  const handleConfirmationPopUp = () => {
    if(isCreateShift){
      dispatch(resetAssignmentFormData());
    navigate('/view-assignment-shift', {state : selectedrowdata});
    }
    else{
      dispatch(resetAssignmentFormData());
      navigate('/assignment');
    }
  }

  return (
    <div>
      {step === 0 && (
        <Col xs={6}>
          <div className="mb-5 backArrow" onClick={handleBackClick}>
            <ArrowBackIcon />
          </div>
        </Col>
      )}
      <Card className="customeboxShadow">
        <div className="px-md-7 px-4 py-10">
          {/* <form className="form"> */}
          <div className="mb-4">
            <div className="container">
              <div className="w-100 position-relative">
                <div className="progress">
                  <div
                    className="progress-bar bg-primary w-100"
                    id="progress-tab"
                    role="progressbar"
                    aria-valuenow={25}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <ul
                  className="nav nav-tabs border-0 w-100 hstack justify-content-between mt-5"
                  id="skk-tabs"
                >
                  <li
                    className="nav-item d-flex flex-column align-items-center"
                    role="presentation"
                  >
                    <button
                      type="button"
                      className="btn btn-sm btn-primary rounded-pill d-flex justify-content-center align-items-center p-3 steperBtn"
                      data-bs-toggle="tab"
                      data-bs-target="#tab1"
                      data-skk-value="1"
                    >
                      <Assignment />
                    </button>
                    <p className="mt-2 mb-0 d-sm-block d-none">
                      Assignment Info
                    </p>
                  </li>
                  <li
                    className="nav-item d-flex flex-column align-items-center"
                    role="presentation"
                  >
                    <button
                      type="button"
                      className="btn btn-sm rounded-pill d-flex justify-content-center align-items-center p-3 steperBtn"
                      style={{
                        border:
                          step >= 1 ? '0px solid ' : '2px solid #919EAB66',
                        backgroundColor: step >= 1 ? '#103558' : '#F9F9F9',
                      }}
                      data-bs-toggle="tab"
                      data-bs-target="#tab2"
                      data-skk-value="2"
                      onClick={() => setIsActive(!isActive)}
                    >
                      <CreateShift fill={step >= 1 ? "#FFFFFF" : "#747F89"}/>
                    </button>
                    <p className="mt-2 mb-0 d-sm-block d-none">Create Shift</p>
                  </li>
                  <li
                    className="nav-item d-flex flex-column align-items-center"
                    role="presentation"
                  >
                    <button
                      type="button"
                      className="btn btn-sm rounded-pill d-flex justify-content-center align-items-center p-3 steperBtn"
                      style={{
                        border:
                          step >= 2 ? '0px solid ' : '2px solid #919EAB66',
                        backgroundColor: step >= 2 ? '#103558' : '#F9F9F9',
                      }}
                      data-bs-toggle="tab"
                      data-bs-target="#tab3"
                      data-skk-value="3"
                    >
                      <AddPatient fill={step >= 2 ? "#FFFFFF" : "#747F89"}/>
                    </button>
                    <p className="mt-2 mb-0 d-sm-block d-none">Add Patient </p>
                  </li>
                  <li
                    className="nav-item d-flex flex-column align-items-center"
                    role="presentation"
                  >
                    <button
                      type="button"
                      className="btn btn-sm rounded-pill d-flex justify-content-center align-items-center p-3 steperBtn"
                      style={{
                        border:
                          step === 3 ? '0px solid ' : '2px solid #919EAB66',
                        backgroundColor: step >= 3 ? '#103558' : '#F9F9F9',
                      }}
                      data-bs-toggle="tab"
                      data-bs-target="#tab4"
                      data-skk-value="4"
                    >
                      <FindClinician fill={(step >= 3 || step >= 4) ? "#FFFFFF" : "#747F89"}/>
                    </button>
                    <p className="mt-2 mb-0 d-sm-block d-none">
                      Find Clinician
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <Container fluid className="mt-6">
            {step === 0 && (
              <>
                <Step_0 isLoading={gettingAssignmentValues} />
              </>
            )}

            {step === 1 && (
              <>
                <Step_1 />
              </>
            )}

            {step === 2 && (
              <>
                <Step_2 />
              </>
            )}

            {step === 3 && (
              <>
                <Step_3 />
              </>
            )}

            {step === 4 && (
              <>
                <Step_4 />
              </>
            )}
          </Container>
        </div>
        {showAddDocumentModal && (
          <AddEditAssignmentDocument
            editData={selectedRow}
            show={showAddDocumentModal}
            handleCallBack={(document: any) => handleCallBack(document)}
            handleClose={e => handleClosedocumentmodal(e)}
          />
        )}
        {showConfirmationPopUp && (
          <ConfirmEventPopup
            showPopup={showConfirmationPopUp}
            handleClose={handleCloseConfirmationPopUp}
            handleConfirmed={() => handleConfirmationPopUp()}
            loading={false}
            title={'Unsaved Changes'}
            message={`Are you sure you want to lose your changes?`}
          />
        )}
      </Card>
    </div>
  );
};

export default Addnewassignment;
