import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MuiTable from '../../../../../components/table/MuiTable';
import {
  convertDateToYYYYMMDDForAssignment,
  convertTimesToUTCForAssignment,
  convertUTCTimesToLocal,
  formatTimeForCheckInOut,
} from '../../../../../helpers/commonFunctions';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import {
  nextStep,
  prevStep,
  resetAssignmentFormData,
  resetStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import { KTIcon } from '../../../../helpers';
import {
  UpdateAssignment,
  addAssignment,
  getCities,
  getCompanyDetailsById,
  getProgram,
  getShift,
} from '../../api';
import './stepper-style.css';
import moment from 'moment';

interface ShiftDetails {
  Id: any;
  assignmentDate: any;
  startTime: any;
  endTime: any;
  invitedUserIds: any[];
  shiftId: any;
}

export const Step_3 = () => {
  const [dateWithDayNames, setDateWithDayNames] = useState<
    { Id: number; date: string;time:string; day: string }[]
  >([]);
  const [idCounter, setIdCounter] = useState(1);
  const [selectedtabledates, setSelectedTableDates] = useState<Date[]>([]);
  const [programname, setProgramName] = useState<any>(String);
  const [skillname, setSKillName] = useState<any>(String);
  const [cityName, setCityName] = useState<any>(String);
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails[]>([]);
  const [selectedprovidersid, setSelectedProvidersId] = useState<any[]>([]);
  const dispatch = useDispatch();
  const formDatas = useSelector((state: any) => state.assignment.formData);
  const selectedproviders = formDatas?.InvitedUserIds;
  const SelecteddateforInvitedProvider = formDatas?.selectedTabelDates;
  const previousshiftdetail = formDatas?.shiftdetail;

  const { data: cityData, mutateAsync: getCityData } = useMutation(
    'cities',
    getCities
  );

  useEffect(() => {
    if (formDatas?.selectedPatientAddress?.StateId || formDatas?.stateId) {
      const stateValue = formDatas?.selectedPatientAddress?.StateId ? formDatas?.selectedPatientAddress?.StateId : formDatas?.stateId;
      getCityData({ stateId: stateValue, countryId: 1, Take: 1000 });
    }
  }, [formDatas?.selectedPatientAddress?.StateId, formDatas?.stateId]);

  useEffect(() => {
    const cityIdValue = formDatas?.cityId ? formDatas?.cityId :  formDatas?.selectedPatientAddress?.CityId
    if(cityData && cityIdValue){
      const cityDataValue = cityData?.Result?.filter((data: any) => data?.Id == cityIdValue);
      setCityName(cityDataValue[0]?.CityName)
    }
  },[cityData, formDatas?.cityId, formDatas?.selectedPatientAddress?.CityId])

  const navigate = useNavigate();

  const [isCreatingUpdatingAssignment, setIsCreatingUpdatingAssignment] =
    useState(false);
  const { data: programData } = useQuery('program-list', getProgram);
  const { data: skillData, mutateAsync: getSkillDataAPI } = useMutation(
    'comapnydetail',
    getCompanyDetailsById
  );
  const shiftData = formDatas?.shiftData;

  useEffect(() => {
    if (formDatas?.companyId) {
      getSkillDataAPI({ id: formDatas?.companyId });
    }
  }, [formDatas?.companyId]);

  const CreateAssignment = async () => {
    setIsCreatingUpdatingAssignment(true);
    let newAssignmentSchedules = [];
    if (formDatas?.Id) {
      newAssignmentSchedules = bindTimeSlotDataOnEdit(true)
    } else {
      newAssignmentSchedules = bindTimeSlotData(true)
    }
    let address1,
      address2,
      cityIdValue,
      stateIdValue,
      zipCodeValue,
      nursingHomeId;
    if (formDatas?.selectLocationType == '2') {
      if (formDatas?.selectedPatientAddress) {
        address1 = formDatas?.selectedPatientAddress?.AddressLine1 ? formDatas?.selectedPatientAddress?.AddressLine1?.trim().replace(/\s+/g, ' ') : '';
        address2 = formDatas?.selectedPatientAddress?.AddressLine2;
        cityIdValue = formDatas?.selectedPatientAddress?.CityId;
        stateIdValue = formDatas?.selectedPatientAddress?.StateId;
        zipCodeValue = formDatas?.selectedPatientAddress?.ZipCode;
        nursingHomeId = formDatas?.selectedPatientAddress?.Id;
      }
    } else {
      address1 = formDatas?.addressLine1 ? formDatas?.addressLine1?.trim().replace(/\s+/g, ' ') : '';
      address2 = '';
      cityIdValue = parseInt(formDatas?.cityId);
      stateIdValue = parseInt(formDatas?.stateId);
      zipCodeValue = formDatas?.zipCode;
    }
    let bodyParams = {
      Id: formDatas?.Id,
      assignmentCode: formDatas?.assignmentCode ? formDatas?.assignmentCode?.trim().replace(/\s+/g, ' ') : '',
      assignmentName: formDatas?.assignmentName ? formDatas?.assignmentName?.trim().replace(/\s+/g, ' ') : '',
      companyId: parseInt(formDatas?.companyId),
      CompanySkillIds: [parseInt(formDatas?.companySkillIds)],
      instruction: formDatas?.instruction ? formDatas?.instruction?.trim().replace(/\s+/g, ' ') : '',
      isRequiredProviderDocumentUpload: true,
      isRequiredPatientSignature: false,
      companyDocumentIds: formDatas?.companyDocumentIds ?? [],
      assignmentDocuments: formDatas?.assignmentDocuments ?? [],
      assignmentSchedules: newAssignmentSchedules,
      ratePerHour: formDatas?.ratePerHour,
      weekOffRate: formDatas?.weekendrate,
      requiredPersonCount: formDatas?.requiredPersonCount,
      nursingHomeId: nursingHomeId,
      patientDetails: {
        id: formDatas?.patientDetails?.id,
        patientCode: formDatas?.patientDetails?.patientCode ?  formDatas?.patientDetails?.patientCode?.trim().replace(/\s+/g, ' ') : '',
        firstName: formDatas?.patientDetails?.firstName ? formDatas?.patientDetails?.firstName?.trim().replace(/\s+/g, ' ') : '',
        lastName: formDatas?.patientDetails?.lastName ? formDatas?.patientDetails?.lastName?.trim().replace(/\s+/g, ' ') : '',
        contactNumber: String(formDatas?.patientDetails?.contactNumber),
        countryCode: String(formDatas?.patientDetails?.countryCode),
        contactPersonFirstName:
          formDatas?.patientDetails?.contactPersonFirstName ? formDatas?.patientDetails?.contactPersonFirstName?.trim().replace(/\s+/g, ' ') : '',
        contactPersonLastName: formDatas?.patientDetails?.contactPersonLastName ? formDatas?.patientDetails?.contactPersonLastName?.trim().replace(/\s+/g, ' ') : '',
        contactPersonContactNumber: String(
          formDatas?.patientDetails?.contactPersonContactNumber
        ),
        patientOtherInformationIds: formDatas?.patientOtherInformationIds,
        note: formDatas?.patientDetails?.note? formDatas?.patientDetails?.note?.trim().replace(/\s+/g, ' ') : '',
      },
      locationTypeId: formDatas?.selectLocationType == '2' ? 2 : 1,
      addressLine1: address1,
      addressLine2: address2,
      cityId: cityIdValue,
      stateId: stateIdValue,
      zipCode: zipCodeValue,
      programId: formDatas?.programId ?? null,
    };
    try {
      setIsCreatingUpdatingAssignment(true);
      let response;
      if (formDatas?.Id) {
        response = await UpdateAssignment(bodyParams);
      } else {
        response = await addAssignment(bodyParams);
      }
      if (response?.IsSuccess) {
        snackActions.success(response.Message);
        navigate('/assignment');
        dispatch(resetAssignmentFormData());
        dispatch(resetStep());
      } else {
        snackActions.error(response?.Message);
      }
    } catch (error) {
      snackActions.error('An unexpected error occurred.');
    } finally {
      setIsCreatingUpdatingAssignment(false);
    }
  };

  const bindTimeSlotData = (isApiCall:boolean = false) =>{
    const newAssignmentSchedules = formDatas?.assignmentSchedules.map((item:any) => {
      return item.timeSlots.map((slot:any) => {
        let assignmentDate = convertDateToYYYYMMDDForAssignment(item.assignmentDate);
        const slotDatas = bindSlotData(assignmentDate,isApiCall,slot)
        return slotDatas;
      });
    }).flat()

    return newAssignmentSchedules;
  }

  const bindSlotData = (assignmentDate:any,isApiCall:boolean = false,slot:any) => {
    if(slot.shiftId){
      const starttime =
      shiftData?.Result?.find(
        (shift:any) => shift.Id == slot.shiftId
      )?.StartTime.slice(0, 5) || '';
      const endtime =
        shiftData?.Result?.find(
          (shift:any) => shift.Id == slot.shiftId
        )?.EndTime.slice(0, 5) || '';
        const localUTCdata = convertUTCTimesToLocal(assignmentDate, starttime, endtime);
        let utcStartTime = assignmentDate + 'T' + (localUTCdata?.startTime || "00:00:00.000Z");
        let utcEndTime = assignmentDate + 'T' + (localUTCdata?.endTime || "00:00:00.000Z");
        let invitedUserIds:any[] = []
      
        if(shiftDetails?.length > 0){
          if(formDatas.Id){
            const shiftDetail = shiftDetails.find(x => {
              return x.startTime === utcStartTime;
            });
            if(shiftDetail){
              invitedUserIds = shiftDetail.invitedUserIds
            }
          }else{
            const shiftDetail = shiftDetails.find(x => {
              const formattedAssignmentDateTime = convertDateToYYYYMMDDForAssignment(x.assignmentDate) + 'T' + formatTimeForCheckInOut(x.assignmentDate);
              return formattedAssignmentDateTime === utcStartTime;
            });
            if(shiftDetail){
              invitedUserIds = shiftDetail.invitedUserIds
            }
          }
        }

        if(isApiCall){
          const utcConvertedData = convertTimesToUTCForAssignment(assignmentDate,localUTCdata?.startTime ,localUTCdata?.endTime );
          utcStartTime = utcConvertedData?.startTime;
          utcEndTime = utcConvertedData?.endTime
        }
      
      const slotObj:any = {
        startTime: utcStartTime,
        endTime: utcEndTime,
        invitedUserIds: invitedUserIds,
        isCustomShiftTime: slot.isCustomShiftTime,
        shiftId: slot.shiftId ? slot.shiftId : null
      }
      if(formDatas?.Id){
        slotObj.Id = 0
      }
      return slotObj;
    }else{
      let formatStartTime = formatTimeForCheckInOut(slot.startTime)
      let formatEndTime = formatTimeForCheckInOut(slot.endTime)
      let utcStartTime = assignmentDate + 'T' + (formatStartTime || "00:00:00.000Z");
      let utcEndTime = assignmentDate + 'T' + (formatEndTime || "00:00:00.000Z");
      let invitedUserIds:any[] = []
      if(shiftDetails?.length > 0){
        if(formDatas.Id){
          const shiftDetail = shiftDetails.find(x => {
            return x.startTime === utcStartTime;
          });
          if(shiftDetail){
            invitedUserIds = shiftDetail.invitedUserIds
          }
        }else{
          const shiftDetail = shiftDetails.find(x => {
            const formattedAssignmentDateTime = convertDateToYYYYMMDDForAssignment(x.assignmentDate) + 'T' + formatTimeForCheckInOut(x.assignmentDate);
            return formattedAssignmentDateTime === utcStartTime;
          });
          if(shiftDetail){
            invitedUserIds = shiftDetail.invitedUserIds
          }
        }
      }

      if(isApiCall){
        const utcConvertedData = convertTimesToUTCForAssignment(assignmentDate, formatStartTime, formatEndTime);
        utcStartTime = utcConvertedData?.startTime;
        utcEndTime = utcConvertedData?.endTime
      }

      const slotObj:any ={
        startTime: utcStartTime,
        endTime: utcEndTime,
        invitedUserIds: invitedUserIds,
        isCustomShiftTime: slot.isCustomShiftTime,
        shiftId: slot.shiftId ? slot.shiftId : null
      }
      if(formDatas?.Id){
        slotObj.Id = 0
      }
      return slotObj;
    }
  }

  const bindTimeSlotDataOnEdit = (isApiCall:boolean = false) =>{ 
    const transformedShiftDetails = formDatas?.assignmentSchedules?.map(
      (detail: any) => {
      return detail.timeSlots.map((slot:any) => {
        const assignmentDate = convertDateToYYYYMMDDForAssignment(detail.assignmentDate);
        if(slot?.Id){
          let formattedStartTime =formatTimeForCheckInOut(slot.startTime)
          let formattedEndTime = formatTimeForCheckInOut(slot.endTime)
          let utcStartTime = assignmentDate + 'T' + (formattedStartTime || "00:00:00.000Z");
          let utcEndTime = assignmentDate + 'T' + (formattedEndTime || "00:00:00.000Z");

          let invitedUserIds:any[] = slot.invitedUserIds
          if(shiftDetails?.length > 0){
            const shiftDetail = shiftDetails.find(x=> x.Id === slot.Id)
             if(shiftDetail){
              const mergedInvitedUserIds = [...invitedUserIds,...shiftDetail.invitedUserIds]
              const uniqueInvitedUserIds = Array.from(new Set(mergedInvitedUserIds));
              invitedUserIds = uniqueInvitedUserIds
             }
          }

          if(isApiCall){
            const utcConvertedData = convertTimesToUTCForAssignment(assignmentDate, formattedStartTime, formattedEndTime);
            utcStartTime = utcConvertedData?.startTime
            utcEndTime = utcConvertedData?.endTime
          }
         
          return {
            Id: slot?.Id,
            startTime: utcStartTime,
            endTime: utcEndTime,
            invitedUserIds: invitedUserIds,
            isCustomShiftTime: slot.isCustomShiftTime,
            shiftId: slot.shiftId ? slot.shiftId : null
          };
        }else{
          const newAssignmentScheduleData = bindSlotData(assignmentDate,isApiCall,slot)
          return newAssignmentScheduleData;
        }
      });
    }).flat();
    return transformedShiftDetails
  }

  useEffect(() => {
    if (previousshiftdetail) {
      setShiftDetails(previousshiftdetail);
    }
  }, [previousshiftdetail]);

  useEffect(() => {
    if (selectedproviders) {
      setSelectedProvidersId(
        selectedproviders.map((provider: any) => provider?.Id)
      );
    }
  }, [selectedproviders]);

  useEffect(() => {
    if (
      selectedprovidersid &&
      selectedprovidersid.length > 0 &&
      SelecteddateforInvitedProvider
    ) {
      const newShiftDetailsArray: ShiftDetails[] = [];
      SelecteddateforInvitedProvider.forEach((date: any) => {
        let existingShiftDetailIndex = -1
        if(formDatas?.Id){
          existingShiftDetailIndex = shiftDetails.findIndex(
            shift =>
              new Date(shift.startTime).getTime() ===
              new Date(date).getTime()
          );
        }else{
          existingShiftDetailIndex = shiftDetails.findIndex(
            shift =>
              new Date(shift.assignmentDate).getTime() ===
              new Date(date).getTime()
          );
        }
        if (existingShiftDetailIndex !== -1) {
          const updatedShiftDetail = {
            ...shiftDetails[existingShiftDetailIndex],
          };
          updatedShiftDetail.invitedUserIds = Array.from(
            new Set([
              ...updatedShiftDetail.invitedUserIds,
              ...selectedprovidersid,
            ])
          );

          newShiftDetailsArray.push(updatedShiftDetail);
        } else {
          const newShiftDetails: ShiftDetails = {
            Id: 0,
            assignmentDate: date,
            startTime: formDatas.assignmentSchedules[0]?.startTime,
            endTime: formDatas.assignmentSchedules[0]?.endTime,
            invitedUserIds: selectedprovidersid,
            shiftId: formDatas.assignmentSchedules.map(
              (time: any) => time.selectedShiftValue
            ),
          };
          newShiftDetailsArray.push(newShiftDetails);
        }
      });
      setShiftDetails(prevShiftDetails => {
        const updatedShiftDetails = [...prevShiftDetails];

        newShiftDetailsArray.forEach(newShiftDetail => {
          if(newShiftDetail?.Id){
            const index = updatedShiftDetails.findIndex(
              shift =>
                new Date(shift.startTime).getTime() ===
                new Date(newShiftDetail.startTime).getTime()
            );
            if (index !== -1) {
              updatedShiftDetails[index] = newShiftDetail;
            } else {
              updatedShiftDetails.push(newShiftDetail);
            }
          }else{
            let index = -1
            if(formDatas?.Id){
              index = updatedShiftDetails.findIndex(
                shift =>
                  new Date(shift.startTime).getTime() ===
                  new Date(newShiftDetail.startTime).getTime()
              );
            }else{
              index = shiftDetails.findIndex(
                shift =>
                  new Date(shift.assignmentDate).getTime() ===
                  new Date(newShiftDetail.assignmentDate).getTime()
              );
            }
            if (index !== -1) {
              updatedShiftDetails[index] = newShiftDetail;
            } else {
              updatedShiftDetails.push(newShiftDetail);
            }
          }
          
        });

        return updatedShiftDetails;
      });
    }
  }, [formDatas, selectedprovidersid]);

  const handleTableCheckboxChange = (row: { date: string,time:string }) => {
    const key = row.date + row.time;
    const [month, day, year] = row.date.split('/').map(Number);
    const [startTime, endTime] = row.time.split(" - ");
    const [hours, minutes] = startTime.split(":").map(Number);
    const date = new Date(year, month - 1, day, hours, minutes);
    setCheckboxStates((prevCheckboxStates: any) => ({
      ...prevCheckboxStates,
      [key]: !prevCheckboxStates[key],
    }));
  
    setSelectedTableDates((prevDates) => {
      const exists = prevDates.some((d) => d.getTime() === date.getTime());
      return exists ? prevDates.filter((d) => d.getTime() !== date.getTime()) : [...prevDates, date];
    });
  };

  const handleSelectAllChange = (event: any) => {
    const isChecked = event.target.checked;
    const updatedCheckboxStates = { ...checkboxStates };
    let updatedSelectedDates: Date[] = [];
  
    dateWithDayNames.forEach((item) => {
      const [month, day, year] = item.date.split('/').map(Number);
      const [startTime, endTime] = item.time.split(" - ");
      const [hours, minutes] = startTime.split(":").map(Number);
      const date = new Date(year, month - 1, day, hours, minutes);
      updatedCheckboxStates[item.date + item.time] = isChecked;
      if (isChecked) {
        updatedSelectedDates.push(date);
      }
    });
  
    setCheckboxStates(updatedCheckboxStates);
    setSelectedTableDates(updatedSelectedDates);
  };

  useEffect(() => {
    if (formDatas?.Id) {
      let shiftDetailsArr:any[] = []
      if (formDatas?.assignmentSchedules.length > 0) {
        const transformedShiftDetails = bindTimeSlotDataOnEdit()
        shiftDetailsArr = transformedShiftDetails;
        setShiftDetails(transformedShiftDetails);
      } 
      const formattedDates = shiftDetailsArr?.map((item: any, index: any) => {
        const startTime=item?.startTime?.split('T')[1]
       const endTime=item?.endTime?.split('T')[1]
       const convertDate = moment(item?.startTime?.split('T')[0])
       const formattedDate = convertDate.format('MM/DD/YYYY');
        const dayOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][convertDate.day()];
        const id = idCounter + index;
        return { Id: id, date: formattedDate,time:startTime+" - "+ endTime, day: dayOfWeek };
      });
      setDateWithDayNames(formattedDates);
    }
  }, [formDatas?.Id]);

  useEffect(() => {
    if (shiftData?.Result?.length > 0 && !formDatas?.Id) {
      const shiftDetailsArr = bindTimeSlotData()
      const formattedDates = shiftDetailsArr?.map((item: any, index: any) => {
       const startTime=item?.startTime?.split('T')[1]
       const endTime=item?.endTime?.split('T')[1]
        const convertDate = moment(item?.startTime?.split('T')[0])
        const formattedDate = convertDate.format('MM/DD/YYYY');
        const dayOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][convertDate.day()];
        const id = idCounter + index;
        return { Id: id, date: formattedDate,time:startTime+" - "+ endTime, day: dayOfWeek };
      });
      setDateWithDayNames(formattedDates);
    }
  }, []);

  const viewinvited = (data: any) => {
    const [month, day, year] = data.date.split('/').map(Number);
    const [startTime, endTime] = data.time.split(" - ");
    const [hours, minutes] = startTime.split(":").map(Number);
    const date = new Date(year, month - 1, day,hours, minutes);
    dispatch(
      setAssignmentFormData({
        selectedTabelDates: selectedtabledates,
        shiftdetail: shiftDetails,
        clinicianshiftdetails: [],
        InvitedUserIds: [],
        tablecheckbox: checkboxStates,
        isforViewInvited: true,
        viewinvitedselecteddate: date,
      })
    );
    dispatch(nextStep());
  };

  useEffect(() => {
    if (formDatas?.programId && programData) {
      const programname = programData?.Result?.find(
        (programID: any) => programID?.Id == formDatas?.programId
      )?.Name;
      setProgramName(programname);
    }
  }, [formDatas, formDatas?.programId, programData]);

  useEffect(() => {
    if (formDatas?.companySkillIds && skillData) {
      const selectedSkillId = formDatas?.companySkillIds;
      const skillname = skillData?.Result?.CompanySkills?.find(
        (skillId: any) => skillId?.Id == selectedSkillId
      );
      setSKillName(skillname?.SkillName);
      dispatch(
        setAssignmentFormData({ skillIdForProviderSearch: skillname?.SkillId })
      );
    }
  }, [formDatas?.companySkillIds, skillData]);

  const normalizeDate = (date: any) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);  // Set time to midnight (00:00:00)
    return normalizedDate;
  };

  const columns: GridColDef[] = [
    {
      field: 'Select All',
      headerName: 'Select All ',
      flex: 0.8,
      sortable: false,
      headerAlign:'center',
      renderHeader: () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
          className="inviteCheckbox"
          type="checkbox"
          checked={Object.keys(checkboxStates).length === dateWithDayNames.length && Object.values(checkboxStates).every(value => value)}
          onChange={(e) => handleSelectAllChange(e)}
        />
          <span style={{ marginLeft: 8, fontSize: '14px', }}>Select All</span>
        </div>
      ),
      renderCell: (data: any) => {
        const currentDate = new Date();
        const [month, day, year] = data?.row?.date?.split('/');
        const date = new Date(`${month}/${day}/${year}`);
        const isPastDate = (normalizeDate(date)?.getTime() < normalizeDate(currentDate)?.getTime())
        return !isPastDate && (
        <input
          className="inviteCheckbox"
          type="checkbox"
          checked={checkboxStates[data.row.date+data.row.time] || false}
          onChange={e => handleTableCheckboxChange(data?.row)}
        />)
      }
    },
    {
      field: 'Shift No',
      headerName: 'Shift No',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row.Id,
    },
    {
      field: 'Date',
      headerName: 'Date',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        return <div>{data?.row?.date}</div>;
      },
    },
    {
      field: 'Time',
      headerName: 'Time',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row.time,
    },
    {
      field: 'Days of the Week',
      headerName: 'Days of the Week',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row.day,
    },
    {
      field: 'Action1',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const [month, day, year] = data.row.date.split('/').map(Number);
        const [startTime, endTime] = data.row.time.split(" - ");
        const [hours, minutes] = startTime.split(":").map(Number);
        const date = new Date(year, month - 1, day,hours, minutes);
        const shiftDetail: any = shiftDetails?.find((detail: any) => {
          const detailDate = new Date(formDatas?.Id ? detail?.startTime :detail?.assignmentDate );
          const isEqualDate = detailDate.getTime() === date.getTime();
          return isEqualDate;
        });
        const invitedUsersCount = shiftDetail?.invitedUserIds?.length || 0;
        return (
          <div
            className="d-flex align-items-center viewInviteBtn"
            onClick={() => viewinvited(data?.row)}
          >
            <span>View Invited ({invitedUsersCount})</span>
          </div>
        );
      },
    },
  ];

  const handleInvite = () => {
    if (!selectedtabledates || selectedtabledates.length === 0) {
      snackActions.error('Please select one shift');
    } else {
      dispatch(
        setAssignmentFormData({
          selectedTabelDates: selectedtabledates,
          shiftdetail: shiftDetails,
          tablecheckbox: checkboxStates,
          isforViewInvited: false,
          InvitedUserIds: [],
        })
      );
      dispatch(nextStep());
    }
  };

  return (
    <>
     <div className='d-flex w-100'>
          <div className='me-4'>
            <label><strong className='me-1'>Assignment Name:</strong> {formDatas?.assignmentName ? formDatas?.assignmentName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Facility:</strong> {formDatas?.companyName ? formDatas?.companyName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Skill:</strong> {formDatas?.skillName ? formDatas?.skillName : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Program:</strong> {formDatas?.programName ? formDatas?.programName : '-'}</label>
          </div>
        </div>
        <div className='d-flex w-100'>
          <div className='me-4'>
            <label><strong className='me-1'>Patient ID:</strong> {formDatas?.patientDetails?.patientCode ? formDatas?.patientDetails?.patientCode : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Patient Name:</strong> {(formDatas?.patientDetails?.firstName && formDatas?.patientDetails?.lastName) ? (formDatas?.patientDetails?.firstName + ' ' +  formDatas?.patientDetails?.lastName) : '-'}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Patient Zipcode:</strong> {formDatas?.selectedPatientAddress?.ZipCode ? formDatas?.selectedPatientAddress?.ZipCode : formDatas?.zipCode}</label>
          </div>
          <div className='me-4'>
            <label><strong className='me-1'>Patient Cityname:</strong> {cityName ? cityName : '-'}</label>
          </div>
        </div>
      <div className="my-5">
        <h3>Invite Clinician</h3>
      </div>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-end">
            {/* For Future Purpose if need to show in the UI */}
            {/* <div className="d-flex align-items-center">
              <p className="pt-2 fw-normal">
                Looking for clinician for Program:{' '}
                <span className="fw-bolder">
                  {programname ? programname : '-'}
                </span>{' '}
                & Skill:{' '}
                <span className="fw-bolder">{skillname ? skillname : '-'}</span>
              </p>
            </div> */}
            <Button
              className="d-flex align-items-center justify-content-end py-3"
              onClick={handleInvite}
            >
              <KTIcon iconName="plus" className="fs-1" />
              Invite
            </Button>
          </div>
        </Col>
      </Row>
      {(isCreatingUpdatingAssignment || !cityName) && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}

      <Row>
        <MuiTable
          addLabel="Add New Clinician"
          columns={columns}
          isAddEnable={false}
          data={dateWithDayNames ?? []}
          isRowClickable={true}
          hideFooterPagination={true}
        />
      </Row>

      <div className="d-flex justify-content-between mt-10">
        <Button
          size="lg"
          className="px-10"
          onClick={() => dispatch(prevStep())}
          variant="secondary"
        >
          Back
        </Button>

        <Button
          type="submit"
          size="lg"
          variant="primary"
          onClick={CreateAssignment}
        >
          {isCreatingUpdatingAssignment ? 'Publishing..' : 'Publish'}
        </Button>
      </div>
    </>
  );
};
